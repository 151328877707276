import MicroFrontend from '@/microfrontends/MicroFrontend'

/**
 * Implementation of micro-frontend module using iframe
 */
export default class IframeMicroFrontend extends MicroFrontend {
  constructor ({ name, url }) {
    super({ name })
    this.type = 'iframe'
    this.iframeUrl = url
    this.loaded = false
  }

  isLoading () {
    return !this.loaded
  }

  mount (elementId) {
    this.moduleEl = document.createElement('iframe')
    this.moduleEl.setAttribute('src', this.iframeUrl)
    this.moduleEl.setAttribute('frameborder', 0)
    this.moduleEl.setAttribute('data-hj-allow-iframe', true) // whitelist iframe in Hotjar
    this.moduleEl.classList.add('iframe-module')
    this.moduleEl.onload = () => {
      this.loaded = true
    }
    this.rootEl = document.getElementById(elementId)
    this.rootEl.appendChild(this.moduleEl)
  }

  unmount () {
    if (!this.rootEl || !this.moduleEl) {
      throw Error('Micro frontend was not mounted yet')
    } else {
      this.rootEl.removeChild(this.moduleEl)
      this.loaded = false
    }
  }
}
