<template>
    <div class="section-dark" v-if="regServices">
      
    </div>
</template>
<script>

export default {
    name: 'ProductSectionDark',
    data() {
        return {
            regServices: null,
            selectedProduct: null,
            activeProduct: 'MiFID II',
        }
    },
    mounted() {
    },
    methods: {
       
    }
}
</script>
<style lang="scss">
@import '~@/assets/variables.scss';

.section-dark {
    text-align: center;
    background-color: $blue-dark;
    padding: $navbar-height;

    h1,
    h2,
    h3 {
        margin-top: 0px;
        color: $white;
    }

    .el-tabs {
        margin: auto;
        display: inline-block;
    }

    .el-tabs__item {
        color: $white;
        font-size: $tab-size;
    }

    .btn:hover,
    .el-tabs__item.is-active,
    .el-tabs__item:hover {
        color: $yellow;
    }

    .el-tabs__active-bar,
    .el-tabs__nav-wrap::after {
        display: none;
    }

    .product-row {
        width: 30%;
        margin: 0 auto;
        .btn {
            margin-bottom: 0;
            font-size: $paragraph-size + 1;
        }

        a,
        p {
            color: $white;
            font-size: $paragraph-size;
            padding: 0 $margin-main;
        }
    }
}
</style>
