<template>
  <div class="mt-12 md:mt-0">
    <h3 class="text-sm font-semibold text-gray-400 tracking-wider uppercase">
      {{ title }}
    </h3>
    <ul class="mt-4 space-y-4">
      <li v-for="(link, index) in links" :key="index">
        <a :href="link.href" class="text-base text-gray-500 hover:text-gray-900">
          {{ link.label }}
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'LandingPageFooterLinks',
  props: {
    title: String,
    links: Array
  }
}
</script>

<style scoped>

</style>
