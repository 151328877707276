<template>
  <div class="h-screen inline-block">
    <div class="hidden h-full md:flex md:flex-shrink-0">
      <div class="flex flex-col w-64">
        <div class="flex flex-col h-0 flex-1 border-r border-gray-200 bg-white">
          <div class="flex-1 flex flex-col pt-5 pb-4 overflow-y-auto">
            <div class="flex items-center flex-shrink-0 px-3 mb-5" v-if="title">
              <h5 class="font-bold text-lg">{{ title }}</h5>
            </div>
            <div v-for="(navSection, index) in navigation" :key="index" class="pb-5">
              <h3 v-if="navSection.label"
                  class="nav-section-title">
                {{ navSection.label }}
              </h3>
              <nav class="flex-1 px-2 bg-white space-y-1">
                <FcnSidebarLink v-for="(link, index) in navSection.children" :key="index" :link="link" />
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FcnSidebarLink from './internal/FcnSidebarLink'
export default {
  name: 'FcnSidebar',
  components: { FcnSidebarLink },
  props: ['title', 'navigation'],
}
</script>

<style scoped lang="scss">
@import '../../assets/variables';

.nav-section-title {
  letter-spacing: 0.05em;
  text-transform: uppercase;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  margin-bottom: 1.25rem;
  font-size: 0.75rem;
  line-height: 1rem;
  font-weight: 600;
  color: $gray-500;
}
</style>
