import Vue from 'vue'
import Vuex from 'vuex'
import data from '@/services/data.js'
import localStorageService from '@/services/LocalStorageService.js'
import { cloneDeep } from 'lodash'

Vue.use(Vuex)

export const store = new Vuex.Store({
  state: {
    navigation: [],
    userRoles: [],
    visibleNavigation: false,
    iframeLoading: false,
    typeOfEnv: '',
    starred: [],
    drawer: false,
    activeTab: 'home',
    activeLink: '',
    typeOfProxy: {}
  },
  getters: {
    navigation: state => cloneDeep(state.navigation),
    typeOfEnv: state => {
      switch (state.typeOfEnv) {
        case 'Production':
          return ''
        default:
          return state.typeOfEnv
      }
    },
    starred: state => {
      return state.starred
    },
    drawer: state => {
      return state.drawer
    },
    activeTab: state => {
      return state.activeTab
    },
    activeLink: state => {
      return state.activeLink
    }
  },
  mutations: {
    setUserRoles (state, userRoles) {
      state.userRoles = userRoles
    },
    setNavigation (state, navigation) {
      state.navigation = navigation
    },
    setVisibleNavigation (state, visibleNavigation) {
      state.visibleNavigation = visibleNavigation
    },
    setIframeLoading (state, iframeLoading) {
      state.iframeLoading = iframeLoading
    },
    setTypeOfEnv (state, type) {
      state.typeOfEnv = type
    },
    setEnvProxy (state, proxy) {
      state.typeOfProxy = proxy
    },
    setStarred (state, starred) {
      state.starred = starred
    },
    setDrawer (state, drawer) {
      state.drawer = drawer
    },
    setActiveTab (state, activeTab) {
      state.activeTab = activeTab
    },
    setActiveLink (state, activeLink) {
      state.activeLink = activeLink
    }
  },
  actions: {
    async updateNavigation ({ commit }, roles) {
      // TODO: loading
      try {
        const navigation = await data.getNavigation()
        commit('setNavigation', navigation)
        commit('setUserRoles', roles)
        // commit('setPages', Pages)
      } catch (err) {
        // TODO: proper error handling in the store & UI (error msg)
        console.log(err)
      }
    },
    loadStarredFromBrowser () {
      const starred = localStorageService.load('starredLinks')
      this.commit('setStarred', !starred ? [] : starred)
    },
    storeStarred ({ commit }, starred) {
      if (starred.constructor !== Array) return
      localStorageService.save('starredLinks', starred)
      commit('setStarred', starred)
    }
  }
})

export default store
