<template>
  <div class="pt-6">
    <button class="flow-root bg-gray-50 rounded-lg px-6 pb-8 duration-200 hover:shadow-lg">
      <div class="-mt-6">
        <div>
          <span class="inline-flex items-center justify-center rounded-md shadow-lg w-72">
            <img class="rounded-lg shadow-xl ring-1 ring-black ring-opacity-5 "
                 :src="image"
                 alt=""
            >
          </span>
        </div>
        <h3 class="mt-8 text-lg font-medium text-gray-700 tracking-tight">
          {{ title }}
        </h3>
        <p class="mt-5 text-base text-gray-500">
          {{ desc }}
        </p>
      </div>
    </button>
  </div>
</template>

<script>
export default {
  name: 'LandingPageCard',
  props: {
    title: String,
    desc: String,
    image: String
  }
}
</script>

<style scoped>

</style>
