import { mapState } from 'vuex'
import { COLUMNS_DEFAULT } from './../components/nav/drawerConfig.js'

export default {
    computed: {
        ...mapState(['activeTab'])
    },
    methods: {
        activePage(name, pageName) {
            return name === pageName
        },
        hasAccess(allowedRoles, userRoles) {
            return (
                !allowedRoles.length ||
                allowedRoles.some(requiredRole =>
                    userRoles.includes(requiredRole)
                )
            )
        },
        // Temporary Dashboard Mockup page
        routeToModule(item, categoryName) {
            const routeToName =
                item.id === 'temp-dashboard'
                    ? 'TempDashboardPage'
                    : 'ModulePage'
            return {
                name: routeToName,
                params: { category: categoryName, page: item.urlParam }
            }
        },
        getRowOfChildren(children, rowIndex, elementsInRow) {
            if (!elementsInRow) elementsInRow = COLUMNS_DEFAULT
            return children.slice(
                rowIndex * elementsInRow,
                elementsInRow + rowIndex * elementsInRow
            )
        },
        getAmountOfRows(children, elementsInRow) {
            if (!children) return 0
            if (!elementsInRow) elementsInRow = COLUMNS_DEFAULT
            return children.length < elementsInRow + 1
                ? 1
                : Math.ceil(children.length / elementsInRow)
        }
    }
}
