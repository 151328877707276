<template>
  <transition name="slide-fade">
    <div class="fixed inset-0 overflow-hidden" v-if="cVisible">
      <div class="absolute inset-0 overflow-hidden">
            <span class="absolute top-0 left-0 w-full h-full"
                  @click="close"></span>
        <section class="panel-slide transform transition ease-in-out duration-500 absolute inset-y-0 right-0 pl-10 max-w-full flex z-50">
          <div class="w-screen max-w-md">
            <div class="h-full flex flex-col py-6 bg-white shadow-xl overflow-y-scroll">
              <div class="px-4 sm:px-6">
                <div class="flex items-start justify-between">
                  <h2 class="text-lg font-medium text-gray-900">{{ title }}</h2>
                  <div class="ml-3 h-7 flex items-center">
                    <button
                        v-for="(btn, index) in buttons"
                        :key="index"
                        @click="(ev) => btn.action(ev)"
                        v-html="btn.label"
                        :title="btn.title"
                        class="px-2 bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none">
                    </button>
                    <button
                        @click="close"
                        class="px-2 bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none">
                      <span class="sr-only">Close panel</span>
                      <i class="fas fa-times"></i>
                    </button>
                  </div>
                </div>
              </div>
              <div class="mt-6 relative flex-1 px-4 sm:px-6">
                <div class="absolute inset-0 px-4 sm:px-6">
                  <slot></slot>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </transition>
</template>

<script>
/**
 * Drawer which slides from right
 * E.g.
 * <FcnSlideOver title="Edit fund" :visible.sync="isVisible">
 *  Slider content
 * </FcnSlideOver>
 * Reference: https://tailwindui.com/components/application-ui/overlays/slide-overs#component-166f09a503f2e34749daca3d7dc50525
 */
export default {
  name: 'FcnSlideOver',
  props: {
    visible: {
      type: Boolean,
    },
    title: {
      type: String,
    },
    /**
     * Array of button objects, with following keys: action (function), label.
     */
    buttons: {
      type: Array,
      default: () => ([]),
    },
  },
  computed: {
    cVisible: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('update:visible', val)
      },
    },
  },
  methods: {
    close () {
      this.cVisible = false
    },
  },
}
</script>

<style scoped>

.panel-slide {
  transform: translateX(0px);
}

.slide-fade-enter-active, .slide-fade-enter-active .panel-slide {
  transition: all .3s ease;
}

.slide-fade-leave-active, .slide-fade-leave-active .panel-slide {
  transition: all .3s ease;
}

.slide-fade-enter .panel-slide, .slide-fade-leave-to .panel-slide {
  transform: translateX(100%);
}
</style>
