<template>
  <span class="inline-block relative">
    <span ref="dropdown-btn" @click="showDropdown = true" class="h-full">
      <slot></slot>
    </span>
    <div ref="dropdown" class="absolute bg-white shadow-lg" v-if="showDropdown" style="top: 100%;"
         :style="{ width: width || '100%' }">
      <div
          class="z-50 bg-white relative border border-gray-300 max-h-60 text-base overflow-auto">
        <slot name="dropdown">
        </slot>
      </div>
    </div>
  </span>
</template>

<script>
export default {
  name: 'FcnCustomDropdown',
  props: {
    /**
     * E.g. '150px'
     */
    width: {
      type: String,
    },
  },
  data () {
    return {
      showDropdown: false,
      listener: null,
    }
  },
  watch: {
    showDropdown (isVisible) {
      if (isVisible) {
        this.listener = this.mouseClickListener.bind(this)
        document.addEventListener('mousedown', this.listener)
      } else {
        document.removeEventListener('mousedown', this.listener)
      }
    },
  },
  methods: {
    mouseClickListener (ev) {
      if (!ev.path.includes(this.$refs.dropdown) && !ev.path.includes(this.$refs['dropdown-btn'])) {
        this.showDropdown = false
      }
    },
  },
}
</script>

<style scoped>

</style>
