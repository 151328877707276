<template>
    <el-drawer
        :visible.sync="drawer"
        :modal="false"
        direction="ttb"
        size="80%"
    >
        <div class="scroll-wrap">
            <div class="drop-nav">
                <StarredRows />
                <NavMainRows />
            </div>
        </div>
    </el-drawer>
</template>

<script>
import NavMainRows from '@/components/nav/NavMainRows.vue'
import StarredRows from '@/components/nav/StarredRows.vue'

export default {
    name: 'NavDrawer',
    components: { NavMainRows, StarredRows },
    computed: {
        drawer: {
            get: function() {
                return this.$store.getters.drawer
            },
            set: function(newValue) {
                this.$store.commit('setDrawer', newValue)
            }
        },
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
@import '~@/assets/variables.scss';
.el-dialog__wrapper {
    top: $navbar-height !important;

    .el-drawer {
        width: 90% !important;
        left: 5% !important;
        box-shadow: $shadow-main;
        opacity: $opacity-header;

        .el-drawer__close-btn:focus,
        .el-drawer__container:focus {
            outline: none !important;
        }

        #el-drawer__title {
            position: absolute;
            right: 0;
        }

        .scroll-wrap {
            height: 72vh;
            overflow: auto;
        }
    }
}
</style>