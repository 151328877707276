<template>
	<el-popover
		:class="avatarColorClass()"
		placement="bottom-start"
		title
		width="300"
		trigger="click"
	>
		<div v-if="user" class="user-card" :class="avatarColorClass()">
			<div class="user-card-header">
				<span class="avatar">{{ userInitials }}</span>
				<p class="full-name">{{ user.FullName }}</p>
				<p>{{ user.Username }}</p>
			</div>
			<el-divider></el-divider>
			<template v-if="userRoles.includes('admin')">
				<el-button
					size="small"
					class="NavBar__btn"
					@click="$router.push({ name: 'AdminPage' })"
					>Admin</el-button
				>
			</template>
			<template>
				<a :href="signOutUrl()">
					<el-button size="small">Log Out</el-button>
				</a>
			</template>
			<el-divider class="help-divider"></el-divider>
			<a target="_blank" class="help"
				>Privacy Policy</a
			>
			<a :href="`mailto:service@fundconnect.com`" class="help">Help</a>
		</div>
		<span class="avatar" slot="reference">{{ userInitials }}</span>
	</el-popover>
</template>

<script>
import { mapState } from 'vuex'

export default {
	name: 'UserPopover',
	data() {
		return {
			user: null,
			userInitials: ''
		}
	},
	mounted() {
		this.user = this.$authSession.User
		this.userInitials = this.user.FullName.split(' ')
			.map((a) => a[0])
			.slice(0, 2)
			.join('')
	},
	methods: {
		signOutUrl() {
			return this.$auth.getSignOffUrl(this.$auth.baseUrl)
		},
		avatarColorClass() {
			let i = this.userInitials.toUpperCase().charCodeAt(0)
			let c = ''
			if (!i) return {}
			if (i < 73) c = 'a-c-1'
			if (i > 72 && i < 81) c = 'a-c-2'
			if (i > 80) c = 'a-c-3'
			return {
				[c]: true
			}
		}
	},
	computed: {
		...mapState(['userRoles'])
	}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
@import '~@/assets/variables.scss';
.header {
	.avatar {
		font-size: 12px;
		float: right;
		margin-top: 8px;
		margin-right: 30px;
		cursor: pointer;
		color: white;
		padding-top: 5px;
		width: 28px;
		height: 28px;
		border-radius: 20px;
		text-align: center;
	}
}

.a-c-1 .avatar {
	background-color: #1c5a8f;
}

.a-c-2 .avatar {
	background-color: #9fa0a2;
}

.a-c-3 .avatar {
	background-color: #00a676;
}

.el-popover {
	padding-top: $margin-main !important;
	padding-left: 0 !important;
	padding-right: 0 !important;

	.user-card {
		text-align: center;

		.user-card-header {
			padding-top: $margin-main;

			.avatar {
				display: block;
				float: none;
				width: 36px;
				height: 36px;
				font-size: 16px;
				padding-top: 7px;
				margin: auto;
				margin-bottom: 10px;
				color: white;
				border-radius: 20px;
				text-align: center;
			}
		}

		p {
			margin: $margin-mini;
		}

		.full-name {
			font-size: $tab-size;
		}

		.help {
			margin: $margin-medium;
			font-size: 12px !important;
			cursor: pointer;
		}

		.help:hover {
			color: $blue-main;
		}

		.help-divider {
			margin-bottom: $margin-small;
		}

		.NavBar__btn {
			margin-right: 10px;
		}
	}
}
</style>
