<template>
  <div class="px-4 py-3 flex items-center justify-between sm:px-6">
    <div class="flex-1 flex items-center justify-between">
      <div v-if="total">
        <p class="text-sm text-gray-700">
          Showing
          <span class="font-medium" v-text="(currentPage - 1) * pageSize + 1"></span>
          -
          <span class="font-medium" v-text="Math.min((currentPage) * pageSize, total)"></span>
          of
          <span class="font-medium">{{ total }}</span>
          results
        </p>
      </div>
      <div>
        <nav class="relative z-0 inline-flex shadow-sm -space-x-px" aria-label="Pagination">
          <button
              @click="$emit('changePage', 1)"
              :disabled="currentPage === 1"
              class="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50">
            <span class="sr-only">First</span>
            <!--  heroicons: chevron-double-left -->
            <svg class="w-4 h-4 text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 19l-7-7 7-7m8 14l-7-7 7-7"/>
            </svg>
          </button>
          <button
              @click="$emit('changePage', currentPage - 1)"
              :disabled="currentPage === 1"
              class="relative inline-flex items-center px-2 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50">
            <span class="sr-only">Previous</span>
            <!--  heroicons: chevron-left -->
            <svg class="w-4 h-4 text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7"/>
            </svg>
          </button>
          <button
              v-if="currentPage - 2 > 0"
              class="cursor-default hidden md:inline-flex relative items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700">
            ...
          </button>
          <button
              @click="$emit('changePage', currentPage - 1)"
              :disabled="currentPage === 1"
              v-if="currentPage - 1 > 0"
              class="relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700">
            {{ currentPage - 1}}
          </button>
          <button
              class="cursor-default bg-gray-100 relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700">
            {{ currentPage}}
          </button>
          <button
              @click="$emit('changePage', currentPage + 1)"
              :disabled="totalPages === currentPage"
              v-if="currentPage + 1 <= totalPages"
              class="hidden md:inline-flex relative items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50">
            {{ currentPage + 1}}
          </button>
          <button
              v-if="currentPage + 2 <= totalPages"
              class="cursor-default hidden md:inline-flex relative items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700">
            ...
          </button>
          <button
              @click="$emit('changePage',  currentPage + 1)"
              :disabled="totalPages === currentPage"
              class="relative inline-flex items-center px-2 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50">
            <span class="sr-only">Next</span>
            <!--  heroicons: chevron-right -->
            <svg class="w-4 h-4 text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7"/>
            </svg>
          </button>
          <button
              @click="$emit('changePage', totalPages)"
              :disabled="totalPages === currentPage"
              class="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50">
            <span class="sr-only">Last</span>
            <!--  heroicons: chevron-right -->
            <svg class="w-4 h-4 text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 5l7 7-7 7M5 5l7 7-7 7"/>
            </svg>
          </button>
        </nav>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FcnPagination',
  props: {
    total: Number,
    currentPage: Number,
    pageSize: Number,
    totalPages: Number,
  },
  data () {
    return {
    }
  },
}
</script>

<style scoped lang="scss">
.pagination-btn {
  width: 30px;
  height: 30px;
  background: #436ec4;
  color: #fff;
  font-size: 18px;
  border: 1px solid #bebebe;
  transition: .2s;
  font-weight: 100;
  margin-right: 5px;
  font-weight: bold;

  &:disabled {
    background: #dcdcdc;
    color: #9d9d9d;
    cursor: not-allowed;
  }

  &:hover {
    background: #2b4986;
  }
}
</style>
