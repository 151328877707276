<template>
    <div class="section-tiles" v-if="fundTechs">
    
    </div>
</template>
<script>
export default {
    name: 'ProductSectionTiles',
    data() {
        return {
            fundTechs: null
        }
    },
    mounted() {
    }
}
</script>
<style lang="scss">
@import '~@/assets/variables.scss';

.section-tiles {
    background-color: $color-background;
    margin: $navbar-height * 2 0;
    padding: $navbar-height 0;
    text-align: center;

    h1,
    h2,
    h3 {
        margin-top: 0px;
    }

    h3 {
        margin: $margin-main;
    }

    .sub-heading {
        margin-bottom: $margin-main * 2;
    }

    .product-row {
        margin: 0 auto;

        .el-col {
            box-shadow: $shadow-small;
            background-color: white;
        }

        .el-col {
            text-align: left;
            position: relative;
            padding: $margin-main / 2 0;
            margin: 0 $margin-main / 2;
            transition: $transition-speed;
            border-radius: $border-radius;

            i {
                margin: $margin-main $margin-main 0;
                font-size: $icon-extra-large;
                color: $color-text-main;
            }

            a,
            p {
                text-align: left;
                font-size: $paragraph-size;
                padding: 0 $margin-main;
            }

            .space-for-readmore {
                height: 50px;
            }

            .btn:hover,
            .contact:hover {
                color: $blue-main;
            }

            .contact-us {
                left: $margin-main;
            }

            .read-more {
                left: $margin-main;
            }

            .btn {
                position: absolute;
                bottom: $margin-main;
                color: $blue-main;
                font-size: $paragraph-size + 1;
            }
        }
    }
}

@media only screen and (max-width: 1600px) {
    .section-tiles {
        .product-row {
            width: 90%;
        }
    }
}
@media only screen and (min-width: 1601px) {
    .section-tiles {
        .product-row {
            width: 80%;
        }
    }
}
</style>
