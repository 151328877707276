<template>
  <main class="flex-1 relative z-0 overflow-y-auto focus:outline-none bg-gray-100" tabindex="0">
    <div class="fcn-page py-6 px-4 sm:px-6 md:px-8">
      <div class="mb-5" v-if="title">
        <h1 class="text-2xl font-semibold text-gray-900" v-html="title"></h1>
      </div>
      <div class="">
        <slot></slot>
      </div>
    </div>
  </main>
</template>

<script>
export default {
  name: 'FcnPage',
  props: {
    title: {
      type: String,
    }
  }
}
</script>

<style scoped lang="scss">
@import '../../assets/variables';
.fcn-page {
  max-width: $container-width;
  margin: 0 auto;
}
</style>
