<template>
  <div>
        <transition-group name="notif-anim" tag="ul"
                          class="fixed inset-0 flex flex-col justify-center px-4 py-6 pointer-events-none sm:p-6 sm:items-start sm:justify-end">
        <div
            v-for="(notification, index) in notifications"
            :key="index"
            class="mb-3 max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
          <div class="p-4">
            <div class="flex items-start">
              <div class="flex-shrink-0">
                <!-- Heroicon name: check-circle -->
                <svg v-if="notification.type === 'success'"
                     class="h-6 w-6 text-green-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                     stroke="currentColor" aria-hidden="true">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                        d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"/>
                </svg>
                <!-- Heroicon name: exclamation-circle -->
                <svg v-if="notification.type === 'warning'"
                     class="h-6 w-6 text-red-400"
                    xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                        d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"/>
                </svg>
              </div>
              <div class="ml-3 w-0 flex-1 pt-0.5">
                <p class="text-sm font-medium text-gray-900" v-html="notification.title">
                </p>
                <p class="mt-1 text-sm text-gray-500" v-if="notification.desc" v-html="notification.desc"></p>
              </div>
              <div class="ml-4 flex-shrink-0 flex">
                <button
                    @click="deleteNotif(notification)"
                    class="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                  <!-- Heroicon name: x -->
                  <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
                       aria-hidden="true">
                    <path fill-rule="evenodd"
                          d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                          clip-rule="evenodd"/>
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
      </transition-group>
  </div>
</template>

<script>
export default {
  name: 'FcnNotification',
  data () {
    return {
      notifications: [],
    }
  },
  methods: {
    add (notif) {
      this.notifications.push(notif)
      setTimeout(() => {
        this.deleteNotif(notif)
      }, 5000)
    },
    deleteNotif (notif) {
      this.notifications = this.notifications.filter(n => n !== notif)
    },
  },
}
</script>

<style scoped>
.notif-anim-enter-active, .notif-anim-leave-active {
  transition: all 1s;
}

.notif-anim-enter, .notif-anim-leave-to {
  opacity: 0;
  transform: translateY(30px);
}
.notif-anim-move {
  transition: transform 1s;
}
</style>
