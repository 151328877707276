<template>
  <div class="adminPage">
    <div class="adminPage__container">
      <h1 class="adminPage__title">
        Edit Portal {{ typeOfEnv }} navigation:
      </h1>
      <v-jsoneditor ref="jsonEditor"
                    v-model="navigationConfig"
                    :options="options"
                    :plus="false"
                    height="80vh"
      />
      <div class="adminPage__tools">
        <el-button icon="el-icon-arrow-left"
                   class="adminPage__btn"
                   @click="$router.push({ name: 'MainPage'})"
        >
          Back to main page
        </el-button>
        <el-button icon="el-icon-check"
                   type="primary"
                   class="adminPage__btn"
                   :disabled="loading"
                   :loading="loading"
                   @click="onNavigationSave"
        >
          Save
        </el-button>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapMutations } from 'vuex'
import $http from '@/services/http'

export default {
  name: 'AdminPage',
  components: {
    VJsoneditor: () => import('v-jsoneditor/src/index')
  },
  data () {
    return {
      navigationConfig: {},
      loading: false,
      options: {
        mode: 'code'
      }
    }
  },
  computed: {
    ...mapState(['userRoles', 'typeOfEnv'])
  },
  mounted () {
    this.setVisibleNavigation(false)
    this.getNavigation()
  },
  methods: {
    ...mapMutations(['setVisibleNavigation']),
    async getNavigation () {
      this.loading = true
      const response = await $http.get('/api/admin/navigation')
      this.loading = false
      this.navigationConfig = response.data
    },
    async onNavigationSave () {
      if (this.validateJSON()) {
        try {
          this.loading = true
          await $http.post('/api/admin/navigation', this.navigationConfig)
          this.$message.success('Navigation updated.')
        } catch (error) {
          return this.$message.error('Navigation could not be saved.')
        } finally {
          this.loading = false
          await this.getNavigation()
        }
      }
    },
    validateJSON () {
      const { editor } = this.$refs.jsonEditor
      try {
        editor.get()
        return true
      } catch (error) {
        this.$message.error('JSON parsing error, please fix your data')
        return false
      }
    }
  }
}
</script>
<style lang="scss" scoped>
@import "~@/assets/variables.scss";

.adminPage {

    &__container {
        position: relative;
        width: 100%;
        max-width: 960px;
        margin: 30px auto;
    }

    &__title {
        color: $grey1;
        display: block;
        font-size: 20px;
        text-decoration: none;
        font-weight: 400;
        margin: 0 0 20px 0;
    }

    &__tools {
        margin: 15px 0;
        display: flex;
        justify-content: flex-end;
    }

    &__btn.el-button--primary {
        /deep/ span {
            color: $white;
        }
    }

    /deep/ .jsoneditor {
        border-color: $fc-blue;
    }

    /deep/ .jsoneditor-menu {
        background-color: $fc-blue;
        border-bottom-color: $fc-blue;
    }
}
</style>
