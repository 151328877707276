class LocalStorageService {
    /**
     * Saves to localstorage
     * @param {string} key
     * @param {*} data
     */
    save(key, data) {
        localStorage.setItem(key, JSON.stringify(data))
    }

    load(key) {
        let res = localStorage.getItem(key)
        if (res === null) return null
        else return JSON.parse(res)
    }
}
export default new LocalStorageService();