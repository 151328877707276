import Vue from 'vue'
import router from './router'
import VueAnalytics from 'vue-analytics'

export function runAnalytics (typeOfEnv) {
  // Google Analytics on Production and Test
  if (typeOfEnv !== 'Development') {
    // Production ID, Test ID
    const gaID = typeOfEnv === 'Production' ? 'UA-140703699-3' : 'UA-140703699-4'

    Vue.use(VueAnalytics, {
      id: gaID,
      router,
      commands: {
        trackNavCategory (name) {
          this.$ga.event('navigationCategories', 'click', name)
        }
      }
    })
  }

  // Hotjar on Production
  if (typeOfEnv === 'Production') {
    const hotjarFunction = `
    (function(h,o,t,j,a,r){
        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
        h._hjSettings={hjid:1753643,hjsv:6};
        a=o.getElementsByTagName('head')[0];
        r=o.createElement('script');r.async=1;
        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
        a.appendChild(r);
    })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`
    const script = document.createElement('script')
    const inlineContent = document.createTextNode(hotjarFunction)
    script.appendChild(inlineContent)
    document.head.appendChild(script)
  }
}
