import MicroFrontend from './MicroFrontend'
import { loadScriptElement } from '@/utils/loadScriptElement'
import MicroFrontendTypes from '@/assets/MicroFrontendTypes'

const envTypes = {
  Production: 'prod',
  Test: 'test',
  Development: 'dev'
}

export default class OsirisMicrofrontend extends MicroFrontend {
  constructor ({ id, name, url, solutionName, isFundList, pageName, params }) {
    super({ id, name, url })
    this.id = id
    this.url = url
    this.type = MicroFrontendTypes.OSIRIS
    this.solutionName = solutionName
    this.isFundList = isFundList
    this.pageName = pageName
    this.params = params
    this.app = null
    this._isLoading = false
    this.loadedScripts = []
  }

  async mount (rootId, { ssoToken = null, env = null }) {
    this._isLoading = true
    await loadScriptElement(this.url)
    this.app = new window.osiris.FcnOsiris()
    this.app.mount({
      containerId: rootId,
      server: this.solutionName,
      solution: 'default',
      pageName: this.pageName,
      token: ssoToken,
      env: envTypes[env],
      params: {
        ...this.params,
        language: 'en-GB',
        currency: 'EUR'
      }
    })

    this._isLoading = false
  }

  unmount () {
    // remove empty stylesheets
    [...document.querySelectorAll('style')].filter(item => !item.innerHTML).forEach(item => item.remove())
    // remove script
    document.querySelectorAll(`script[src="${this.url}"]`).forEach(item => item.remove())
    this.app.unmount()
  }

  isLoading () {
    return this._isLoading
  }
}
