import GenericDialog from '../internal/GenericDialog.vue'

export const OpenDialogFactory = ({ Vue, router, store }) => {
  return function (component, opts = {
    title: '',
    props: {},
    width: '40%',
    allowEasyExit: true,
  }) {
    return new Promise((resolve, reject) => {
      const DialogInstance = Vue.extend(GenericDialog)
      const oComponent = new DialogInstance({
        propsData: {
          allowEasyExit: opts.allowEasyExit,
          width: opts.width,
          componentInstance: component,
          options: {
            cb: opts.cb,
            title: opts.title,
          },
          props: opts.props,
        },
        router,
        store,
      }).$mount()

      const oEl = document.getElementById('app').appendChild(oComponent.$el)
      oComponent.$on('input', function (value) {
        if (opts.onInput) {
          opts.onInput(value)
        }
      })
      oComponent.$on('exit', function (value) {
        oEl.remove()
        resolve(value)
      })
    })
  }
}
