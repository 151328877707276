<template>
    <div>
        <div class="footer">
         
        </div>
    </div>
</template>
<script>
export default {
    name: 'FooterBar',
    data() {
        return {
        }
    }
}
</script>
<style lang="scss">
@import '~@/assets/variables.scss';

.footer {
    height: $navbar-height * 2;
    text-align: center;
    background-color: $blue-main;
    
    .logo {
        cursor: pointer;
    }

    .logo:nth-child(3) {
        position: relative;
        top: -23px;
        padding: 0 25px;
    }
}
</style>
