<template>
  <div>
    <component :is="link.to ? 'router-link' : 'button'"
               :to="link.to"
               @click="onClick"
               active-class="bg-gray-100"
               class="fcn-sidebar-link w-full group flex items-center">
      <span v-html="link.label"></span>
      <svg v-if="link.children" class="ml-auto h-5 w-5 transform group-hover:text-gray-400 ease-in-out duration-200"
           :class="{
            'text-gray-400 rotate-90': isVisible,
            'text-gray-300': !isVisible,
           }"
           viewBox="0 0 20 20" aria-hidden="true">
        <path d="M6 6L14 10L6 14V6Z" fill="currentColor"/>
      </svg>
    </component>
    <div v-if="isVisible" class="space-y-1">
      <router-link v-for="(childLink, index) in link.children" :index="index" :to="childLink.to"
                   class="group w-full flex items-center pl-11 pr-2 py-2 text-sm font-medium text-gray-600 rounded-md hover:text-gray-900 hover:bg-gray-50">
        {{ childLink.label }}
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "FcnSidebarLink",
  props: {
    link: {
      type: Object,
    }
  },
  data () {
    return {
      isVisible: false,
    }
  },
  methods: {
    onClick () {
      if (this.link.children) {
        this.isVisible = !this.isVisible
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import '../../../assets/variables';

.fcn-sidebar-link {
  color: $gray-600;
  padding: .5rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  transition-duration: $transition-duration;
  font-weight: 500;
  &:hover {
    color: $gray-900;
    background-color: $gray-100;
  }
}
</style>
