<template>
  <button :type="type"
          v-bind="$props"
          v-on="$listeners"
          class="fcn-form-button inline-flex items-center font-medium focus:outline-none"
          :class="{
            'btn-default': theme === 'default',
            'btn-primary': theme === 'primary',
            'btn-text': theme === 'text',
            'btn-small': size === 'small',
            'btn-medium': size === 'medium',
            'btn-large': size === 'large',
          }"
          :disabled="disabled"
  >
    <slot></slot>
  </button>
</template>

<script>
/**
 * Button component
 * Can be in different themes: default, primary
 * Can be a circle
 * Can be disabled
 */
export default {
  props: {
    type: {
      type: String,
      default: 'button',
      validator: (val) => ['button', 'submit'].includes(val),
    },
    size: {
      type: String,
      default: 'medium',
      validator: (val) => ['small', 'medium', 'large'].includes(val),
    },
    /**
     * @values default, primary
     */
    theme: {
      type: String,
      default: 'default',
      validator: (val) => ['default', 'primary', 'text'].includes(val),
    },
    disabled: Boolean,
  },
}
</script>

<style scoped lang="scss">
@import '../assets/variables';

.fcn-form-button {
  font-size: $text-md;
  border-radius: $border-radius;
  border: 1px solid transparent;
  &:disabled {
    cursor: not-allowed;
  }
}
.btn-default {
  color: $gray-700;
  background-color: $white;
  border: 1px solid $gray-400;
  transition-duration: $transition-duration;
  font-weight: bold;

  &:hover:enabled {
    background-color: $gray-100;
  }

  &:disabled {
    background-color: $gray-100;
    color: $gray-400;
    border: 1px solid $gray-200;
  }
}
.btn-primary {
  background-color: $primary;
  color: $white;
  transition-duration: $transition-duration;
  font-weight: bold;

  &:hover:enabled {
    background-color: $primary-600;
  }

  &:disabled {
    background-color: $gray-100;
    color: $gray-400;
  }
}

.btn-text {
  color: $gray-800;
  &:hover:enabled {
    background-color: $gray-100;
  }

  &:disabled {
    color: $gray-400;
  }
}


.btn-small {
  font-size: $text-sm;
  padding: 4px 12px;
}

.btn-medium {
  font-size: $text-md;
  padding: 6px 22px;
}

.btn-large {
  font-size: $text-lg;
  padding: 10px 34px;
}
</style>
