<template>
  <div v-loading="loading" class="module-page">
    <div class="module-page__container">
      <div
        v-if="navigation.length && getModuleConfig().hierarchy"
        id="module-aside"
        class="module-page__module module-page__module--aside"
      />

      <div
        v-if="module.type !='osiris'"
        :id="rootId"
        class="module-page__module"
      />

      <div
        :id="rootIdOs"
        v-esle
        class="module-page__module"
      />
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import IframeMicroFrontend from '../microfrontends/IframeMicroFrontend'
import JavascriptMicroFrontend from '../microfrontends/JavascriptMicroFrontend'
import OsirisMicrofrontend from '../microfrontends/OsirisMicrofrontend'
import MicroFrontendTypes from '../assets/MicroFrontendTypes'

export default {
  name: 'ModulePage',
  data () {
    return {
      initialized: false,
      module: null,
      rootId: 'module-root',
      rootIdOs: 'module-root-osiris',
      pageName: this.$route.params.page,
      pageSource: this.$route.params.source,
      categoryName: this.$route.params.category
    }
  },
  computed: {
    ...mapState(['navigation', 'isMiniHeader', 'pages', 'typeOfProxy']),
    loading () {
      return this.module ? this.module.isLoading() : true
    }
  },
  watch: {
    navigation: {
      handler () {
        if (!this.getModuleConfig()) {
          this.$router.push({ name: 'MainPage' })
        } else if (!this.initialized) {
          this.initModule()
        }
      }
    }
  },
  mounted () {
    if (this.navigation.length) {
      this.initModule()
    }
  },
  beforeRouteUpdate (to, from, next) {
    this.pageName = to.params.page
    this.categoryName = to.params.category
    this.pageSource = to.params.source
    this.module.unmount()
    this.initModule()
    next()
  },
  beforeDestroy () {
    this.module.unmount()
  },
  methods: {
    ...mapMutations(['setVisibleNavigation']),
    initModule () {
      this.initialized = true
      this.setVisibleNavigation(false)
      this.module = this.createModule(this.getModuleConfig())
      this.module.mount(this.setRootId(this.module.type), {
        basePath: this.getModuleConfig().basePath,
        ssoToken: this.$authSession.Token,
        env: this.$store.state.typeOfEnv,
        allowProxy: this.typeOfProxy
      })
    },

    setRootId (moduleType) {
      if (moduleType === MicroFrontendTypes.JS) return this.rootId
      if (moduleType === MicroFrontendTypes.OSIRIS) return this.rootIdOs
    },
    getModuleConfig () {
      if (this.pageSource === 'pages') {
        return this.pages.find(
          (module) => module.name === this.pageName
        )
      }
      const category = this.navigation.find(
        (item) => item.name === this.categoryName
      )
      return category.children.find(
        (module) => module.urlParam === this.pageName
      )
    },
    createModule (moduleData) {
      if (moduleData.type === MicroFrontendTypes.IFRAME) {
        return new IframeMicroFrontend(moduleData)
      } else if (moduleData.type === MicroFrontendTypes.JS) {
        return new JavascriptMicroFrontend(moduleData)
      } else if (moduleData.type === MicroFrontendTypes.OSIRIS) {
        return new OsirisMicrofrontend({ ...moduleData, token: this.$authSession.Token })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/variables.scss';

.module-page {
    &__container {
        height: calc(100vh - #{$navbar-height});
        display: flex;
        overflow: scroll;
    }

    &__module {
        flex: 1;
        width: 100%;

        &--aside {
            max-width: 400px;
            overflow: auto;
        }

        /deep/ .iframe-module {
            height: 100%;
            width: 100%;
        }
    }
}
</style>
