<template>
  <transition
      :name="name"
      :appear="appear"
      mode="out-in"
      @after-leave="$emit('afterLeave')">
    <slot></slot>
  </transition>
</template>

<script>
export default {
  name: 'FcnAnimated',
  props: {
    name: {
      type: String,
      default: 'opacity',
    },
    appear: {
      type: Boolean,
      default: true,
    },
  },
}
</script>

<style scoped>
.opacity-enter-active, .opacity-leave-active {
  transition: opacity .15s;
}

.opacity-enter, .opacity-leave-to {
  opacity: 0;
}

.dialog-enter-active, .dialog-leave-active {
  transition: transform .15s, opacity .15s;
}

.dialog-enter, .dialog-leave-to {
  transform: scale(0.95);
  opacity: 0;
}
</style>
