export async function loadScriptElement (scriptSrc) {
  return new Promise(resolve => {
    const script = document.createElement('script')
    script.onload = function () {
      resolve()
    }
    script.src = scriptSrc
    document.getElementsByTagName('head')[0].appendChild(script)
  })
}
