import FcnSlideOver from './components/FcnSlideOver'
import FcnDropdown from './components/FcnDropdown'
import FcnTable from './components/FcnTable'
import FcnFormItem from './components/FcnFormItem'
import FcnButton from './components/FcnButton'
import { FcnNotification } from './services/FcnNotification'
import FcnSidebar from './components/sidebar/FcnSidebar'
import FcnPage from './components/page/FcnPage'
import { OpenDialogFactory } from './services/OpenDialog'
import FcnTile from './components/tile/FcnTile'

export const FcnVuePlugin = {
  install (Vue, options) {
    Vue.prototype.$notif = FcnNotification
    Vue.component('FcnPage', FcnPage)
    Vue.component('FcnSlideOver', FcnSlideOver)
    Vue.component('FcnDropdown', FcnDropdown)
    Vue.component('FcnTable', FcnTable)
    Vue.component('FcnTile', FcnTile)
    Vue.component('FcnFormItem', FcnFormItem)
    Vue.component('FcnButton', FcnButton)
    Vue.component('FcnSidebar', FcnSidebar)

    const openDialog = OpenDialogFactory({
      Vue,
      router: options.router,
      store: options.store,
    })

    Vue.prototype.$openDialog = openDialog
  },
}
