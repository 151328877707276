<template>
    <el-autocomplete
        clearable
        :class="{search: true, searching: search.length > 0}"
        v-model="search"
        size="mini"
        :trigger-on-focus="false"
        :hide-loading="true"
        :fetch-suggestions="querySearch"
        placeholder="Search Applications"
        @select="handleSearchSelect"
    ></el-autocomplete>
</template>

<script>
import { mapState } from 'vuex'

export default {
    name: 'SearchBar',
    data() {
        return {
            search: '',
        }
    },
    computed: {
        ...mapState(['navigation']),
        activeTab: {
            get: function() {
                return this.$store.getters.activeTab
            },
            set: function(newValue) {
                this.$store.commit('setActiveTab', newValue)
            }
        }
    },
    methods: {
        querySearch(queryString, cb) {
            this.$store.commit('setDrawer', false)
            if (!queryString || queryString === '') return null

            var results = this.flattenLinks(this.navigation)
                .filter(this.filterLinks(queryString))
                .map(link => {
                    return {
                        value: `${link.categoryName} - ${link.name}`,
                        link: link
                    }
                })
            cb(results)
        },
        flattenLinks(nav) {
            return nav.reduce((acc, curr) => {
                if (!curr.disabled) return acc.concat(curr.children)
                else return acc
            }, [])
        },
        filterLinks(queryString) {
            return link => {
                if (
                    link.name
                        .toLowerCase()
                        .includes(queryString.toLowerCase()) === true
                )
                    return true
                else if (
                    link.description
                        .join()
                        .replace(/,/g, '')
                        .toLowerCase()
                        .includes(queryString.toLowerCase()) === true
                )
                    return true
                else return false
            }
        },
        handleSearchSelect(item) {
            this.$router.push({
                name: 'ModulePage',
                params: { source: 'nav', category: item.link.categoryName, page: item.link.name }
            })
            this.activeTab = item.link.categoryId
            this.search = ''
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
@import '~@/assets/variables.scss';

.search {
    position: absolute !important;
    right: 75px;
    width: 12%;
    top: $margin-small;
    z-index: 100;
    transition: 0.1s;

    input {
        border-radius: 30px;
    }
}

.search.searching {
    width: 22%;
    transition: 0.2s;
}
</style>
