<template>
  <div class="fcn-table"
       :class="{
        'fcn-table-default': !theme || theme === 'default',
        'fcn-table-primary': theme === 'primary',
       }"
  >
    <div class="py-2 flex justify-between items-end mb-2">
      <div class="flex-1 pr-5">
        <h1 class="text-lg pb-3" v-if="title">{{ title }}
          ({{ `${list.length !== data.length ? `${list.length}/` : ''}` }}{{ data.length }})</h1>
        <p v-if="description" v-html="description"></p>
        <div v-if="data.length && filterBy" class="">
          <FcnFormItem title="Filter" placeholder="Search..." v-model="filterValues.query" @submit="$emit('filter', filterValues)" />
        </div>
      </div>
      <div class="">
        <FcnDropdown
            btn-class="mr-2"
            theme="default"
            label="Actions"
            width="150px"
            :buttons="rowButtons.map(btn => ({
              label: btn.label,
              action: handleCommand(btn),
              disabled: value.length === 0 || (btn.max && btn.max < value.length),
            }))"/>
        <slot name="buttons"></slot>
        <FcnFormButton v-for="(btn, index) in buttons"
                       class="mr-1"
                       theme="primary"
                       :key="index"
                       @click="btn.action"
                       :disabled="btn.validator ? !btn.validator() : false"
                       v-html="btn.label"
        />
      </div>
    </div>
    <div v-if="filters" class="mb-2">
      <div class="flex flex-wrap">
        <div v-for="(filter, index) in filters"
             class="w-1/3 pr-2 pb-2"
             :key="index">
          <FcnFormItem :title="filter.label"
                       placeholder="Search..."
                       type="select"
                       v-model="filterValues[filter.prop]"
                       :multiple="filter.multiple"
                       :options="filter.options"/>
        </div>
      </div>
      <div class="text-right">
        <FcnButton theme="text" @click="onFiltersClear" class="mr-2">Clear</FcnButton>
        <FcnButton theme="primary" @click="$emit('filter', filterValues)">Filter</FcnButton>
      </div>
    </div>
    <template v-if="list.length">
      <!-- Table -->
      <div v-if="type === 'table'" class="flex flex-col">
        <div class="overflow-x-auto">
          <div class="py-2 align-middle inline-block min-w-full">
            <div class="shadow-sm overflow-hidden border-b border-gray-200">
              <table class="min-w-full divide-y divide-gray-200 border border-gray-100">
                <thead>
                  <th style="width: 30px; text-align: center;" v-if="selectable || rowButtons.length">
                    <input type="checkbox" :checked="allSelected" @input="toggleAll"/>
                  </th>
                <slot name="thead"></slot>
                </thead>
                <tbody class="bg-white divide-y divide-gray-200">
                <tr v-for="(row, index) in list" :key="index" :class="{ 'selected': value.includes(row) }">
                  <td style="width: 30px; text-align: center;" v-if="selectable || rowButtons.length">
                    <input type="checkbox" :value="row[keyBy] || row" v-model="value"/>
                  </td>
                  <slot :row="row" :index="index"></slot>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <!-- Custom list -->
      <div v-if="type === 'custom-list'" class="flex flex-wrap">
        <template v-for="(listItem, index) in list">
          <slot :row="listItem" :index="index"></slot>
        </template>
    </div>
    </template>
    <div v-else class="text-gray-800 text-center text-xl py-4 px-3">
      No data
    </div>
    <FcnPagination v-if="hasPagination"
                   :total="total"
                   :total-pages="totalPages"
                   :current-page="currentPage"
                   :page-size="pageSize"
                   @changePage="onChangePage"
    />
  </div>
</template>

<script>
import FcnFormButton from './FcnButton.vue'
import FcnDropdown from './FcnDropdown.vue'
import FcnPagination from './pagination/FcnPagination'

/**
 * Table component, with client-side or server-side pagination, filtering and selecting
 * Can emit 'changePage' event with page number for pagination.
 * Can be of type table or custom list, where developer can specify how does each item is presented.
 */
export default {
  name: 'FcnTable',
  components: { FcnPagination, FcnDropdown, FcnFormButton },
  props: {
    theme: {
      type: String,
      default: 'default',
      validator: value => ['default', 'primary'].includes(value),
    },
    type: {
      type: String,
      default: 'table',
      validator: value => ['table', 'custom-list'].includes(value),
    },
    filters: {
      type: Array,
    },
    /**
     * Used for v-model binding. Tracks which rows are selected, optional.
     */
    value: {
      type: Array,
      default: () => ([]),
    },
    /**
     * Optional, if specified, is used to track items from "data" by this property. E.g. "_id".
     */
    keyBy: String,
    /**
     * Array of objects which will be presented in table.
     */
    data: Array,
    loading: Boolean,
    title: String,
    description: String,
    /**
     * Array of objects with 'label' and 'action' property. Action is a function which will be called on click.
     */
    buttons: {
      type: Array,
      default: () => ([]),
    },
    /**
     * Which property from 'data' array should be filterable
     */
    filterBy: String,
    rowButtons: {
      type: Array,
      default: () => ([]),
    },
    /**
     * Whether the table should allow selection. If true, additional column will appear with checkboxes.
     */
    selectable: Boolean,
    // pagination
    clientSidePagination: {
      type: Object,
      default: () => ({
        enabled: false,
        pageSize: null,
      }),
    },
    pagination: {
      type: Object,
      default: () => ({
        enabled: false,
        page: null,
        pageSize: null,
        total: null,
        totalPages: null,
      }),
    },
  },
  data () {
    return {
      filterValues: {},
      clientPage: 1,
    }
  },
  watch: {
    'value' (val) {
      this.$emit('input', val)
    },
  },
  computed: {
    isSelectable () {
      return this.rowButtons && this.rowButtons.length
    },
    list () {
      let list = this.data
      if (this.clientSidePagination.enabled) {
        list = list.slice((this.currentPage - 1) * this.pageSize, this.currentPage * this.pageSize)
      }
      return list
    },
    allSelected () {
      return this.value.length === this.list.length
    },
    hasPagination () {
      return this.clientSidePagination.enabled || this.pagination.enabled
    },
    currentPage () {
      if (this.hasPagination) {
        return (this.pagination || {}).page || this.clientPage
      }
      return null
    },
    totalPages () {
      if (this.hasPagination) {
        if (this.pagination.enabled) {
          return this.pagination.totalPages
        }
        return Math.ceil(this.data.length / this.pageSize)
      }
      return null
    },
    pageSize () {
      if (this.hasPagination) {
        return this.pagination.pageSize || this.clientSidePagination.pageSize || 50
      }
      return null
    },
    total () {
      if (this.clientSidePagination.enabled) {
        return this.data.length
      }
      if (this.pagination.enabled) {
        return this.pagination.total
      }
      return null
    },
  },
  methods: {
    handleCommand (rowBtn) {
      if (rowBtn.max === 1) {
        if (this.keyBy) {
          rowBtn.action(this.list.find(row => row[this.keyBy] === this.value[0]))
        } else {
          rowBtn.action(this.value[0])
        }
      } else {
        if (this.keyBy) {
          rowBtn.action(this.list.filter(row => this.value.includes(row[this.keyBy])))
        } else {
          rowBtn.action(this.value)
        }
      }
    },
    onChangePage (pageNum) {
      if (this.clientSidePagination.enabled) {
        this.clientPage = pageNum
      }
      if (this.pagination.enabled) {
        this.$emit('changePage', pageNum)
      }
    },
    toggleAll () {
      if (this.allSelected) {
        this.value = []
      } else {
        this.value = this.list.map(row => row[this.keyBy] || row)
      }
    },
    onFiltersClear () {
      this.filterValues = {}
      this.$notif.success('Filters cleared')
    }
  },
}
</script>

<style scoped lang="scss">
@import '../assets/variables.scss';

$header-bg: $gray-50;
$header-color: $gray-500;

.fcn-table {
  table {
    table-layout: fixed;
    tr:nth-child(2n) {
      background-color: #f5f5f5;
    }
  }

  th, td {
    padding: 8px 12px;
  }

  thead th {
    letter-spacing: 0.05em;
    text-transform: uppercase;
    --tw-text-opacity: 1;
    text-align: left;
    font-size: 0.75rem;
    line-height: 1rem;
    font-weight: 500;
  }

  tbody td {
    font-size: $text-sm;
    line-height: $text-sm * 1.2;
    font-weight: 500;

    a {
      color: #2758c5;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &.fcn-table-default {
    $header-bg: $gray-100;
    $header-color: $gray-500;
    $body-color: $gray-900;

    thead {
      background-color: $header-bg;
    }

    thead th {
      color: $header-color;
    }

    tbody td {
      color: $body-color;
    }
  }

  &.fcn-table-primary {
    $header-bg: $primary-600;
    $header-color: $white;
    $body-color: $gray-900;

    thead {
      background-color: $header-bg;
    }

    thead th {
      color: $header-color;
    }

    tbody td {
      color: $body-color;
    }
  }
}
</style>
