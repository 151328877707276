<template>
  <div class="fcn-tile shadow-sm">
    <div class="px-4 py-5 sm:px-6 border-b border-gray-200"
         v-if="title"
         @click="onTitleSectionClick"
         :class="{ 'cursor-pointer': isFolder }">
      <div class="flex">
        <span v-if="isFolder">
          <i class="text-xl pr-3 fas" :class="{'fa-caret-right': !isOpen, 'fa-caret-down': isOpen }"></i>
        </span>
        <h1 class="fcn-tile-title">{{ title }}</h1>
        <slot name="header-buttons"></slot>
      </div>
      <p class="fcn-tile-desc" v-if="description">{{ description }}</p>
    </div>
    <div class="px-4 py-3 sm:p-6">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "FcnTile",
  props: {
    isFolder: Boolean,
    title: String,
    description: String,
  },
  data () {
    return {
      isOpen: false,
    }
  },
  created () {
    this.isOpen = this.isFolderOpen
  },
  methods: {
    onTitleSectionClick () {
      this.isOpen = !this.isOpen
    },
  },
}
</script>

<style scoped lang="scss">
@import "../../assets/variables.scss";
.fcn-tile {
  background-color: $white;
  border: 1px solid $gray-200;
  border-radius: $border-radius-lg;
}
.fcn-tile-title {
  color: #141322;
  font-size: 15px;
  text-transform: uppercase;
  font-weight: bold;
}
.fcn-tile-desc {
  color: #a1a1c2;
  font-size: 14px;
  padding-top: 5px;
}
</style>
