<template>
  <footer class="bg-gray-50" aria-labelledby="footerHeading">
    <h2 id="footerHeading" class="sr-only">
      Footer
    </h2>
    <div class="max-w-md mx-auto pt-12 px-4 sm:max-w-7xl sm:px-6 lg:pt-16 lg:px-8">
      <div class="xl:grid xl:grid-cols-3 xl:gap-8">
        <div class="space-y-8 xl:col-span-1">
          <img class="h-10"
               src="@/assets/nordea_logo.png"
               alt="Company name"
          >
          <p class="text-gray-500 text-base">
            Web solutions for Nordea.
          </p>
        </div>
        <div class="mt-12 grid grid-cols-2 gap-8 xl:mt-0 xl:col-span-2">
          <LandingPageFooterLinks title="Dashboards"
                                  :links="[
                                    {
                                      label: 'Dashboard #1',
                                      href: '#',
                                    },
                                    {
                                      label: 'Dashboard #2',
                                      href: '#',
                                    },
                                    {
                                      label: 'Dashboard #3',
                                      href: '#',
                                    }
                                  ]"
          />
        </div>
      </div>
      <div class="mt-12 border-t border-gray-200 py-8">
        <p class="text-base text-gray-400 xl:text-center">
          &copy; 2021 FundConnect A/S
        </p>
      </div>
    </div>
  </footer>
</template>

<script>
import LandingPageFooterLinks from '@/views/landingPage/internal/LandingPageFooterLinks'
export default {
  name: 'LandingPageFooter',
  components: { LandingPageFooterLinks }
}
</script>

<style scoped>

</style>
