var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.category && !_vm.category.disabled)?_c('el-row',{staticClass:"category"},[(_vm.showDescription(_vm.category.showDescription))?_c('el-col',{staticClass:"category-info",attrs:{"offset":0,"span":6}},[_c('h2',[_vm._v(_vm._s(_vm.category.name))]),_vm._l((_vm.category.description),function(des,i){return _c('p',{key:i,domProps:{"innerHTML":_vm._s(des)}})})],2):_vm._e(),_c('el-col',{attrs:{"offset":_vm.rowOffSet(_vm.category.showDescription),"span":_vm.rowSpan(_vm.category.showDescription)}},_vm._l((_vm.getAmountOfRows(
				_vm.category.children,
				_vm.category.numberOfColumns
			)),function(row,i){return _c('el-row',{key:row},_vm._l((_vm.getRowOfChildren(
					_vm.category.children,
					i,
					_vm.category.numberOfColumns
				)),function(item,j){return _c('el-col',{key:j,attrs:{"offset":1,"span":_vm.colSpan(_vm.category.numberOfColumns)}},[_c('i',{class:("" + (item.icon))}),_c('div',{staticClass:"link"},[(item.id)?_c('span',{staticClass:"star"},[_c('i',{class:("" + (_vm.getStarCssClasses(item))),on:{"click":function($event){return _vm.starredService.addStar(item)}}})]):_vm._e(),_c('router-link',{attrs:{"to":{
							name: 'ModulePage',
							params: {
								source: 'nav',
								category: _vm.category.name,
								page: item.urlParam
							}
						},"title":item.title}},[_c('h2',{on:{"click":function($event){return _vm.linkClick(item)}}},[_vm._v(_vm._s(item.name))])])],1),_vm._l((item.description),function(des,k){return _c('p',{key:k,domProps:{"innerHTML":_vm._s(des)}})})],2)}),1)}),1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }