var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('button',_vm._g(_vm._b({staticClass:"fcn-form-button inline-flex items-center font-medium focus:outline-none",class:{
          'btn-default': _vm.theme === 'default',
          'btn-primary': _vm.theme === 'primary',
          'btn-text': _vm.theme === 'text',
          'btn-small': _vm.size === 'small',
          'btn-medium': _vm.size === 'medium',
          'btn-large': _vm.size === 'large',
        },attrs:{"type":_vm.type,"disabled":_vm.disabled}},'button',_vm.$props,false),_vm.$listeners),[_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }