/* eslint-disable no-useless-catch */
import $http from '@/services/http'

export default {
  async getNavigation() {
    let result

    try {
      result = await $http.get('/api/navigation')
    } catch (err) {
      throw err
    }

    return result.data
  }
}
