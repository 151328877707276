<template>
    <div class="section-cards" v-if="ecosystem">
     
    </div>
</template>
<script>

export default {
    name: 'ProductSectionCards',
    data() {
        return {
            ecosystem: null
        }
    },
    mounted() {
    }
}
</script>
<style lang="scss">
@import '~@/assets/variables.scss';

.section-cards {
    margin: $navbar-height * 2 auto $navbar-height;
    text-align: center;

    .product-row {
        margin: 0 auto;

        .btn:hover {
            color: $blue-main;
        }

        .el-col:hover {
            box-shadow: $shadow-main;
        }

        .el-col {
            margin: $margin-main $margin-main / 2;
            text-align: justify;
            position: relative;
            border: 1px solid $color-border;
            transition: $transition-speed;
            border-radius: $border-radius;

            .space-for-readmore {
                height: 60px;
            }

            .card-header {
                text-align: center;
            }

            i {
                margin: $margin-main * 2 $margin-main $margin-main;
                font-size: $icon-extra-large;
                color: $color-text-main;
            }

            h3 {
                margin: 0 $margin-main;
            }

            a,
            p {
                font-size: $paragraph-size;
                margin: 0 $margin-main;
                padding: $margin-small $margin-main;
            }

            .btn {
                position: absolute;
                bottom: $margin-main;
                color: $blue-main;
                font-size: $paragraph-size + 1;
            }
        }
    }

    .sign-up {
        display: inline-block;
        padding: 10px 30px;
        background-color: #318dd8;
        border: none;
        border-radius: 4px;
        color: white;
        font-size: $tab-size;
    }

    .sign-up:focus {
        background-color: #318dd8;
    }

    .sign-up:active,
    .sign-up:hover {
        background-color: #54a0de;
    }
}

@media only screen and (max-width: 1600px) {
    .section-cards {
        .product-row {
            width: 80%;
        }
    }
}
@media only screen and (min-width: 1601px) {
    .section-cards {
        .product-row {
            width: 70%;
        }
    }
}
</style>
