<template>
  <div id="fcn-app" v-if="initialized">
    <slot></slot>
    <FcnNotificationsView ref="notif-component"/>
  </div>
</template>

<script>
import FcnNotificationsView from '../internal/FcnNotificationsView'
import { FcnNotification } from '../services/FcnNotification'

export default {
  name: 'FcnApp',
  components: { FcnNotificationsView },
  data () {
    return {
      initialized: false,
    }
  },
  async created () {
    await import('../assets/tailwind.css')
    this.initialized = true
    await this.$nextTick()
    FcnNotification.mount(this.$refs['notif-component'])
  },
}
</script>

<style scoped lang="scss">
@import '../assets/variables';

#fcn-app {
  font-family: $font-family;
}
</style>
