import { render, staticRenderFns } from "./FcnPage.vue?vue&type=template&id=9bdccb30&scoped=true&"
import script from "./FcnPage.vue?vue&type=script&lang=js&"
export * from "./FcnPage.vue?vue&type=script&lang=js&"
import style0 from "./FcnPage.vue?vue&type=style&index=0&id=9bdccb30&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9bdccb30",
  null
  
)

export default component.exports