<template>
  <div class="mainPage">
    <LandingPage />
  </div>
</template>
<script>
import FooterBar from '@/components/mainpage/FooterBar.vue'
import MainBanner from '@/components/mainpage/MainBanner.vue'
import ProductSectionCards from '@/components/mainpage/ProductSectionCards.vue'
import ProductSectionDark from '@/components/mainpage/ProductSectionDark.vue'
import ProductSectionTiles from '@/components/mainpage/ProductSectionTiles.vue'
import LandingPage from '@/views/landingPage/LandingPage'

export default {
  name: 'MainPage',
  components: {
    LandingPage,
    MainBanner,
    ProductSectionCards,
    ProductSectionDark,
    ProductSectionTiles,
    FooterBar
  }
}
</script>
<style lang="scss">
@import '~@/assets/variables.scss';

.el-autocomplete-suggestion li,
input,
span,
a,
p,
h1,
h2,
h3 {
    font-family: $font-family-main;
    font-weight: 400;
    color: $color-text-main;
    text-decoration: none;
}

p {
    line-height: $line-height;
    font-size: $paragraph-size;
}

.btn {
    cursor: pointer;
}

.mainPage {
    overflow: hidden;
}
</style>
