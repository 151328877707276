var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('footer',{staticClass:"bg-gray-50",attrs:{"aria-labelledby":"footerHeading"}},[_c('h2',{staticClass:"sr-only",attrs:{"id":"footerHeading"}},[_vm._v(" Footer ")]),_c('div',{staticClass:"max-w-md mx-auto pt-12 px-4 sm:max-w-7xl sm:px-6 lg:pt-16 lg:px-8"},[_c('div',{staticClass:"xl:grid xl:grid-cols-3 xl:gap-8"},[_vm._m(0),_c('div',{staticClass:"mt-12 grid grid-cols-2 gap-8 xl:mt-0 xl:col-span-2"},[_c('LandingPageFooterLinks',{attrs:{"title":"Dashboards","links":[
                                  {
                                    label: 'Dashboard #1',
                                    href: '#',
                                  },
                                  {
                                    label: 'Dashboard #2',
                                    href: '#',
                                  },
                                  {
                                    label: 'Dashboard #3',
                                    href: '#',
                                  }
                                ]}})],1)]),_vm._m(1)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"space-y-8 xl:col-span-1"},[_c('img',{staticClass:"h-10",attrs:{"src":require("@/assets/nordea_logo.png"),"alt":"Company name"}}),_c('p',{staticClass:"text-gray-500 text-base"},[_vm._v(" Web solutions for Nordea. ")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mt-12 border-t border-gray-200 py-8"},[_c('p',{staticClass:"text-base text-gray-400 xl:text-center"},[_vm._v(" © 2021 FundConnect A/S ")])])}]

export { render, staticRenderFns }