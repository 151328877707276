<template>
  <div class="fcn-vue-dialog-container"
       :class="{ 'closed': !visible }">
      <span class="fcn-popup-dialog-mask"
            @click="onMaskClick"
            v-if="visible"></span>
    <FcnAnimated name="opacity">
        <span class="fcn-popup-dialog-mask mask-bg"
              v-if="visible"></span>
    </FcnAnimated>
    <FcnAnimated name="dialog" @afterLeave="$emit('exit')">
      <div v-if="visible" class="fcn-popup-dialog-content"
           :style="{
            'width': width,
           }">
        <component :is="componentInstance" @exit="onExit" v-bind="props" @input="val => $emit('input', val)"/>
      </div>
    </FcnAnimated>
  </div>
</template>
<script>
import FcnAnimated from '../components/FcnAnimated'
export default {
  components: { FcnAnimated },
  props: {
    componentInstance: {
      type: [Object, Function],
      required: true,
    },
    options: Object,
    props: Object,
    width: {
      type: String,
      default: '60%',
    },
    closeOnMaskClick: {
      type: Boolean,
      default: true,
    },
  },
  data () {
    return {
      visible: true,
    }
  },
  methods: {
    close () {
      this.visible = false
    },
    onMaskClick () {
      if (this.closeOnMaskClick) {
        this.close()
      }
    },
    cb (...props) {
      this.visible = false
      if (this.options.cb) {
        this.options.cb(...props)
      }
    },
    onExit (ev) {
      this.visible = false
      // this.$emit('exit', ev)
    },
  },
  watch: {
    visible (val) {
      if (!val) {
        // this.$emit('exit', null)
      }
    },
  },
}
</script>
<style lang="scss">
.v-modal {
  z-index: 500 !important;
}

.el-dialog__wrapper {
  z-index: 2002 !important;
}
.fcn-vue-dialog-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1001;

  .fcn-popup-dialog-mask {
    position: fixed;
    top: 0;
    z-index: 10;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .mask-bg {
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 5;
  }

  .fcn-popup-dialog-container {
    position: fixed;
    z-index: 20;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .fcn-popup-dialog-container.closed {
    pointer-events: none;
  }

  .fcn-popup-dialog-content {
    display: block;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    margin-top: 100px;
    margin-bottom: 100px;
    background-color: #fff;
    z-index: 20;
    will-change: transform, opacity;
  }
}
</style>
