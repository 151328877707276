import axios from 'axios'
const $http = axios.create()

export function injectTokenToRequests(token) {
  $http.interceptors.request.use(async function (request) {
    request.headers.Authorization = `Bearer ${token}`
    return Promise.resolve(request)
  }, function (error) {
    return Promise.reject(error)
  })
}

export default $http
