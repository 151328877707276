import ModulePage from './views/ModulePage'
import MainPage from './views/MainPage'
import AdminPage from './views/AdminPage'
import store from './store'

const routes = [
  {
    path: '/',
    name: 'MainPage',
    component: MainPage
  },
  {
    path: '/module/:source/:category/:page/:modulePath*',
    name: 'ModulePage',
    component: ModulePage
  },
  {
    path: '/admin',
    name: 'AdminPage',
    component: AdminPage,
    beforeEnter (to, from, next) {
      const { userRoles } = store.state
      const hasPermission = userRoles.includes('admin')
      if (hasPermission) {
        next()
      } else {
        next({
          name: 'MainPage'
        })
      }
    }
  }
]

export default routes
