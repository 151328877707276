import MicroFrontend from '@/microfrontends/MicroFrontend'

const _loadedScripts = []
const isScriptLoaded = url => _loadedScripts.includes(url)
/**
 * Implementation of micro-frontend module using self-mounting javascript and css files
 * Loaded javascript file must expose globally available function which can be used to mount module
 */
export default class JavascriptMicroFrontend extends MicroFrontend {
  constructor ({ id, name, jsPath, cssPath, hierarchy = false }) {
    super({ id, name, jsPath, cssPath })
    this.id = id
    this.jsFilePath = jsPath
    this.cssFilePath = cssPath
    this._isLoading = false
    this.cssScript = null
    this.hierarchy = hierarchy
  }

  _loadScript (url) {
    if (isScriptLoaded(url)) {
      return Promise.resolve()
    }
    const script = document.createElement('script')
    script.src = url
    script.type = 'text/javascript'
    document.body.appendChild(script)

    return new Promise((resolve, reject) => {
      script.onload = function () {
        _loadedScripts.push(url)
        resolve()
      }
      script.onerror = function (e) {
        reject(new Error(e.toString()))
      }
    })
  }

  _loadStylesheet (url) {
    return new Promise((resolve, reject) => {
      const cssScript = document.createElement('link')
      cssScript.href = url
      cssScript.rel = 'stylesheet'
      document.head.appendChild(cssScript)
      cssScript.onload = function () {
        resolve(cssScript)
      }
      cssScript.onerror = function (e) {
        reject(new Error(e.toString()))
      }
    })
  }

  async mount (rootId, {
    basePath = '/',
    ssoToken = null,
    env = null,
    allowProxy = {}
  }) {
    this._isLoading = true

    const modulePromises = [
      this._loadScript(this.jsFilePath),
      this._loadStylesheet(this.cssFilePath)
    ]
    const hierarchyPromises = this.hierarchy
      ? [
        this._loadScript('/api/module/hierarchy/js/app.js'),
        this._loadStylesheet('/api/module/hierarchy/css/app.css')
      ]
      : []
    const cssScript = (await Promise.all(modulePromises.concat(hierarchyPromises)))[1]

    this.cssScript = cssScript

    const elJs = document.getElementById(rootId)
    const divJs = document.createElement('div')
    const childJs = elJs.appendChild(divJs)

    if (this.hierarchy) {
      window.microfrontends[this.id].mount(childJs, {
        basePath,
        ssoToken,
        allowProxy,
        env,
        subModules: [
          (...props) => {
            const el = document.getElementById('module-aside')
            const div = document.createElement('div')
            const child = el.appendChild(div)
            window.microfrontends.hierarchy.mount(child, { ssoToken, allowProxy }, ...props)
          }
        ]
      })
    } else {
      window.microfrontends[this.id].mount(childJs, {
        basePath,
        ssoToken,
        env,
        allowProxy
      })
    }

    this._isLoading = false
  }

  unmount () {
    if (window.microfrontends.hierarchy) {
      window.microfrontends.hierarchy.unmount()
    }
    window.microfrontends[this.id].unmount()
    this.cssScript.parentNode.removeChild(this.cssScript)
  }

  isLoading () {
    return this._isLoading
  }
}
