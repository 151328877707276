<template>
    <div class="starred" v-if="activeTab === 'starred' && starredService">
        <el-row
            v-for="(row, i) in getAmountOfRows(starredService.starredCategories(starred), 3)"
            :key="row"
        >
            <el-col
                :offset="0"
                :span="7"
                v-for="(group, j) in getRowOfChildren(starredService.starredCategories(starred), i, 3)"
                :key="j"
            >
                <h3>{{group.name}}</h3>
                <div
                    class="starred-link"
                    v-for="(item, j) in starredService.getLinksFromIds(group._links)"
                    :key="j"
                >
                    <router-link
                        :to="{ name: 'ModulePage', params: {source: 'nav', category: group.name, page: item.urlParam } }"
                        :title="item.title"
                    >
                        <span @click="linkClick(item)">{{item.name}}</span>
                    </router-link>
                    <i class="el-icon-close remove" @click="starredService.removeStar(item.id)"></i>
                </div>
            </el-col>
        </el-row>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import helpers from '@/mixins/helpers.js'
import StarredService from '@/services/StarredService.js'

export default {
    name: 'StarredRows',
    data() {
        return {
            starredService: null
        }
    },
    mixins: [helpers],
    mounted() {
        this.starredService = StarredService
    },
    computed: {
        ...mapState(['activeTab', 'starred']),
        drawer: {
            get: function () {
                return this.$store.getters.drawer
            },
            set: function (newValue) {
                this.$store.commit('setDrawer', newValue)
            }
        },
        activeLink: {
            get: function () {
                return this.$store.getters.activeLink
            },
            set: function (newValue) {
                this.$store.commit('setActiveLink', newValue)
            }
        }
    },
    methods: {
        linkClick(link) {
            this.activeLink = link
            this.drawer = false
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
@import '~@/assets/variables.scss';

.starred {
    width: 70%;
    margin: 3vh 10vw;

    h3 {
        margin-bottom: $margin-medium;
        font-size: $heading-small;
        color: $color-text-main;
    }

    h3:hover {
        color: $color-text-main;
    }

    .el-col {
        margin-bottom: 50px;
    }

    .starred-link {
        position: relative;
        margin-bottom: $margin-small;

        a {
            color: $color-text-main;
            font-size: $tab-size;
        }

        span:hover {
            color: $blue-main;
        }

        a {
            margin: 0 !important;
        }

        .remove {
            position: relative;
            top: 2px;
            margin-left: $margin-small;
            font-size: $icon-small !important;
            color: $fc-red !important;
            opacity: 0;
            transition: $transition-speed;
            cursor: pointer;
        }
    }
    .starred-link:hover {
        .remove {
            opacity: 1;
            transition: $transition-speed;
            transition-delay: 0.1s;
        }
    }
}
</style>
