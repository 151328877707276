<template>
	<el-row class="category" v-if="category && !category.disabled">
		<el-col
			v-if="showDescription(category.showDescription)"
			class="category-info"
			:offset="0"
			:span="6"
		>
			<h2>{{ category.name }}</h2>
			<p
				v-for="(des, i) in category.description"
				:key="i"
				v-html="des"
			></p>
		</el-col>

		<el-col
			:offset="rowOffSet(category.showDescription)"
			:span="rowSpan(category.showDescription)"
		>
			<el-row
				v-for="(row, i) in getAmountOfRows(
					category.children,
					category.numberOfColumns
				)"
				:key="row"
			>
				<el-col
					:offset="1"
					:span="colSpan(category.numberOfColumns)"
					v-for="(item, j) in getRowOfChildren(
						category.children,
						i,
						category.numberOfColumns
					)"
					:key="j"
				>
					<i :class="`${item.icon}`"></i>
					<div class="link">
						<span class="star" v-if="item.id">
							<i
								:class="`${getStarCssClasses(item)}`"
								@click="starredService.addStar(item)"
							></i>
						</span>
						<router-link
							:to="{
								name: 'ModulePage',
								params: {
									source: 'nav',
									category: category.name,
									page: item.urlParam
								}
							}"
							:title="item.title"
						>
							<h2 @click="linkClick(item)">{{ item.name }}</h2>
						</router-link>
					</div>
					<p
						v-for="(des, k) in item.description"
						:key="k"
						v-html="des"
					></p>
				</el-col>
			</el-row>
		</el-col>
	</el-row>
</template>

<script>
import { mapState } from 'vuex'
import helpers from '@/mixins/helpers.js'
import StarredService from '@/services/StarredService.js'
import { COLUMNS_DEFAULT, SHOW_DESCRIPTION_DEFAULT } from './drawerConfig.js'

export default {
	name: 'CategoryRows',
	mixins: [helpers],
	data() {
		return {
			starredService: null
		}
	},
	mounted() {
		this.starredService = StarredService
	},
	computed: {
		...mapState(['navigation', 'activeTab', 'starred']),
		drawer: {
			get: function () {
				return this.$store.getters.drawer
			},
			set: function (newValue) {
				this.$store.commit('setDrawer', newValue)
			}
		},
		activeLink: {
			get: function () {
				return this.$store.getters.activeLink
			},
			set: function (newValue) {
				this.$store.commit('setActiveLink', newValue)
			}
		},
		category() {
			return this.navigation.find((category) => {
				category.children = category.children.filter(
					(child) => !child.disabled
				)
				return category.id === this.activeTab
			})
		}
	},
	methods: {
		linkClick(link) {
			this.activeLink = link
			this.drawer = false
		},
		getStarCssClasses(item) {
			return this.starred.some((starId) => starId === item?.id)
				? 'el-icon-star-on star-active'
				: 'el-icon-star-off'
		},
		showDescription(showDescription) {
			if (showDescription === undefined)
				showDescription = SHOW_DESCRIPTION_DEFAULT
			return showDescription
		},
		rowSpan(showDescription) {
			if (showDescription === undefined)
				showDescription = SHOW_DESCRIPTION_DEFAULT
			return showDescription ? 17 : 24
		},
		rowOffSet(showDescription) {
			if (showDescription === undefined)
				showDescription = SHOW_DESCRIPTION_DEFAULT
			return showDescription ? 1 : 0
		},
		colSpan(numberOfColumns) {
			if (!numberOfColumns) numberOfColumns = COLUMNS_DEFAULT
			if (numberOfColumns == 2) return 9
			if (numberOfColumns == 3) return 7
			if (numberOfColumns == 4) return 5
			if (numberOfColumns == 5) return 3
			else return 5
		}
	}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
@import '~@/assets/variables.scss';

.category {
	margin: 3vh 10vw;

	a {
		color: $blue-main;
	}
	i {
		margin-top: $margin-main;
		font-size: $icon-large;
		color: $color-text-main;
	}

	.link {
		position: relative;

		.star {
			.el-icon-star-on,
			.el-icon-star-off {
				position: absolute;
				left: -24px;
				width: 24px;
				height: 20px;
				font-size: 16px;
				color: $grey2;
				opacity: 0;
				cursor: pointer;
			}

			.star-active {
				color: $yellow;
				opacity: 1;
			}
		}

		h2 {
			display: inline-block;
			margin-bottom: 0;
			font-size: $heading-small;
		}
	}

	.link:hover {
		.el-icon-star-on,
		.el-icon-star-off {
			opacity: 1;
			transition: $transition-speed;
			transition-delay: 0.1s;
		}
	}

	a:hover,
	h2:hover,
	h3:hover {
		color: $blue-main;
	}

	.category-info {
		margin-top: 5vh;

		h2 {
			font-size: $heading-xs;
			color: $color-text-main;
		}

		h2:hover {
			color: $color-text-main;
		}

		i {
			font-size: $icon-extra-large;
		}
	}
}
</style>
