<template>
    <el-carousel trigger="click" :interval="12000" arrow="always">

    </el-carousel>
</template>
<script>
export default {
    name: 'MainBanner',
    data() {
        return {
            carouselTexts: null,
        }
    },
    mounted() {
    }
}
</script>
<style lang="scss">
@import '~@/assets/variables.scss';

.el-carousel {
    .el-carousel__item {
        background-color: $blue-main;

        .carousel-content {
            color: $white;
            background: rgba(28, 90, 143, 0.7);
            box-shadow: $shadow-main;
            border-top: 1px solid rgba(250, 250, 250, 0.7);
            border-bottom: 1px solid rgba(250, 250, 250, 0.7);

            .carousel-text {
                padding: $margin-main;
                text-align: center;
                h1 {
                    margin-bottom: 0;
                    color: $white;
                }

                h2 {
                    margin-top: $margin-main;
                    color: $yellow-light;
                }
            }

            .image {
                position: absolute;
                left: 0px;
                bottom: 0px;
                width: 100vw;
                min-width: 1450px;
                z-index: -1;
                opacity: 0.7;
            }
        }
    }
}

@media only screen and (max-width: 1600px) {
    .el-carousel__container {
        height: 300px !important;

        .carousel-content {
            margin: 80px 0;

            h1 {
                font-size: $heading-medium;
            }

            h2 {
                font-size: $heading-small;
            }
        }
    }
}
@media only screen and (min-width: 1601px) {
    .el-carousel__container {
        height: 300px !important;

        .carousel-content {
            margin: 80px 0;
        }
    }
}
</style>
