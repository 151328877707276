import Vue from 'vue'
import App from './App'
import router from './router'
import store from './store'

import { injectTokenToRequests } from './services/http'
import axios from 'axios'
import AuthPlugin from '@fundconnect/vue-auth-plugin'

import { runAnalytics } from './analytics.js'

import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import ElementUI from 'element-ui'
import locale from 'element-ui/lib/locale/lang/en'
import './assets/styles.scss'
import { FcnVuePlugin } from '../libs/fcn-vue/install'

// Fetching environment type
axios.get('/api/env').then(reply => {
  const typeOfEnv = reply.data.Environment
  const typeOfProxy = reply.data.proxy
  let ssoUrl

  switch (typeOfEnv) {
    case 'Development':
      ssoUrl = 'https://sso.fcn-dev.com/'
      break
    case 'Test':
      ssoUrl = 'https://sso.fcn-test.com/'
      break
    case 'Production':
      ssoUrl = 'https://sso.fundconnect.com/'
      break
  }

  store.commit('setTypeOfEnv', typeOfEnv)
  store.commit('setEnvProxy', typeOfProxy)

  // FundConnect Vue AuthPlugin connecting with SSO
  Vue.use(AuthPlugin, {
    baseUrl: ssoUrl,
    success: (auth, session) => {
      injectTokenToRequests(session.Token)
    }
  })
  Vue.use(FcnVuePlugin, { router, store })

  // Analytics: Google Analytics & Hotjar
  runAnalytics(typeOfEnv)

  // Vue config
  Vue.config.productionTip = false

  // Font Awesome / Fort Awesome
  library.add(fas, far, fab)
  Vue.component('fas', FontAwesomeIcon)

  // Element UI
  Vue.use(ElementUI, { locale })

  new Vue({
    router,
    store,
    components: { App },
    render: h => h(App)
  }).$mount('#nexus-app')
})
