<template>
  <div class="form-item">
    <label class="block text-sm font-medium text-gray-900">
      {{title}}
    </label>
    <template v-if="type === 'text'">
        <div class="mt-1">
          <input type="text"
                 v-model="cValue"
                 @keydown.enter.prevent="$emit('submit')"
                 :placeholder="placeholder"
                 class="p-2 bg-white shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border border-gray-300">
        </div>
    </template>
    <template v-if="type === 'textarea'">
      <div class="mt-1">
        <input type="textarea"
               v-model="cValue"
               @keydown.enter="$emit('submit')"
               :placeholder="placeholder"
               class="p-2 bg-white shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border border-gray-300">
      </div>
    </template>
    <template v-if="type === 'number'">
      <div class="mt-1">
        <input type="number"
               @keydown.enter="$emit('submit')"
               v-model="cValue"
               class="p-2 bg-white shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border border-gray-300">
      </div>
    </template>
    <template v-if="type === 'file'">
      <input type="file" @input="onFileInput" :accept="accept" />
    </template>
    <template v-if="type === 'checkbox'">
      <input type="checkbox" v-model="cValue" style="font-size: 10px; text-align: left; display: inline-block;"/>
    </template>
    <template v-if="type === 'radio'">
      <label v-for="(option, index) in options" :key="index" class="block">
        <input type="radio" v-model="cValue"
               class="mr-2"
               :value="option.value || option"/>
        {{ option.label || option }}
      </label>
    </template>
    <template v-if="type === 'select' && !multiple">
      <FcnCustomDropdown class="w-full">
        <div
            class="bg-white mt-1 cursor-pointer relative block w-full pl-3 pr-10 py-2 text-base border border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
          <div>
            {{ selectValueLabel }}
            <input type="text" style="visibility: hidden;"/>
          </div>
          <span class="absolute top-0 right-0 h-full flex items-center px-3 text-gray-500">
            <i  class="fas fa-chevron-down"></i>
          </span>
        </div>
        <template v-slot:dropdown>
          <ul tabindex="-1" role="listbox" aria-labelledby="listbox-label"
              class="w-full z-50 relative py-1 text-base outline-none">
            <FcnFormItem type="text"
                         class="mx-2 mb-1"
                         v-if="allowSearch"
                         v-model="filterValue"
                         placeholder="Search..."/>
            <label v-for="listItem in listOptions"
                   :key="listItem.value || listItem"
                   class="cursor-pointer"
            >
              <li role="option"
                  :class="{ 'bg-gray-100': value === listItem.value || value === listItem }"
                  @click="ev => onSelectItemClick(listItem)"
                  class="text-gray-900 select-none relative py-2 pl-3 pr-9">
                <span class="font-normal block truncate">
                {{ listItem.label || listItem }}
              </span>
                <span class="text-indigo-600 absolute inset-y-0 right-0 flex items-center pr-4">
                <i class="fas fa-check" v-if="value === listItem.value || value === listItem"></i>
              </span>
              </li>
            </label>
          </ul>
        </template>
      </FcnCustomDropdown>
    </template>
    <template v-if="type === 'select' && multiple">
      <div class="relative">
        <div @click="showDropdown = !showDropdown"
             class="bg-white mt-1 cursor-pointer relative block w-full pl-3 pr-10 py-2 text-base border border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
          <div>
            {{ (cValue || []).length }} selected
          </div>
          <span class="absolute top-0 right-0 h-full flex items-center px-3 text-gray-500">
            <!-- Heroicons: chevron-down -->
            <svg class="w-4 h-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"/>
            </svg>
          </span>
        </div>

        <div class="absolute mt-1 w-full bg-white shadow-lg" v-if="showDropdown">
          <span @click="showDropdown = false"
                style="
            position: fixed;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;"></span>
          <ul tabindex="-1" role="listbox" aria-labelledby="listbox-label"
              class="z-50 bg-white relative border border-gray-300 max-h-60 py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm outline-none">
            <FcnFormItem v-if="allowSearch"
                         class="mx-2 mb-1"
                         v-model="filterValue"
                         placeholder="Search..."/>
            <label v-for="(listItem, index) in listOptions"
                   :key="index"
                   class="cursor-pointer"
            >
              <li role="option"
                  :class="{ 'bg-gray-100': cValue.includes(listItem.value || listItem) }"
                  class="text-gray-900 select-none relative py-2 pl-3 pr-9">
                <input type="checkbox" class="hidden" :value="listItem.value || listItem" v-model="cValue"/>
                <span class="font-normal block truncate">
                {{ listItem.label || listItem }}
              </span>
                <span class="text-indigo-600 absolute inset-y-0 right-0 flex items-center pr-4">
                <i class="fas fa-check" v-if="cValue.includes(listItem.value || listItem)"></i>
              </span>
              </li>
            </label>

            <FcnFormItem v-if="allowCreate"
                         class="mx-2 mb-1"
                         v-model="createNewValue"
                         @submit="onCreateNewListItem"
                         placeholder="Create new"/>
          </ul>
        </div>
      </div>
    </template>
    <template v-if="type === 'remote' && !multiple">
      <FcnCustomDropdown class="w-full">
        <div class="bg-white mt-1 cursor-pointer relative block w-full pl-3 pr-10 py-2 text-base border border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
          <div>
            {{ selectValueLabel }}
            <input type="text" style="visibility: hidden;"/>
          </div>
          <span class="absolute top-0 right-0 h-full flex items-center px-3 text-gray-500">
            <i class="fas fa-chevron-down"></i>
          </span>
        </div>
        <template v-slot:dropdown>
          <ul tabindex="-1" role="listbox" aria-labelledby="listbox-label"
              class="w-full z-50 relative py-1 text-base outline-none">
            <FcnFormItem type="text" class="mx-2 mb-1" @input="ev => debouncedRemoteMethod(ev)"
                         placeholder="Search..."/>
            <label v-for="listItem in remoteItems"
                   :key="listItem.value || listItem"
                   class="cursor-pointer">
              <li role="option"
                  @click="ev => onSelectItemClick(listItem)"
                  class="text-gray-900 select-none relative py-2 pl-3 pr-9">
                <span class="font-normal block truncate">
                  {{ listItem.label || listItem }}
                </span>
              </li>
            </label>
          </ul>
        </template>
      </FcnCustomDropdown>
    </template>
    <template v-if="type === 'remote' && multiple">
      <FcnCustomDropdown class="w-full">
        <div class="bg-white mt-1 cursor-pointer relative block w-full pl-3 pr-10 py-2 text-base border border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
          <div>
            {{ value.length }} selected
          </div>
          <span class="absolute top-0 right-0 h-full flex items-center px-3 text-gray-500">
            <i class="fas fa-chevron-down"></i>
          </span>
        </div>
        <template v-slot:dropdown>
          <ul tabindex="-1" role="listbox" aria-labelledby="listbox-label"
              class="w-full z-50 relative py-1 text-base outline-none">
            <FcnFormItem type="text" class="mx-2 mb-1" @input="ev => debouncedRemoteMethod(ev)"
                         placeholder="Search..."/>
            <label v-for="listItem in remoteItems"
                   :key="listItem.value || listItem"
                   class="cursor-pointer">
              <li role="option"
                  :class="{ 'bg-gray-100': cValue.includes(listItem.value || listItem) }"
                  class="text-gray-900 select-none relative py-2 pl-3 pr-9">
                <input type="checkbox" class="hidden" :value="listItem.value || listItem" v-model="cValue"/>
                <span class="font-normal block truncate">
                  {{ listItem.label || listItem }}
                </span>
              </li>
            </label>
          </ul>
        </template>
      </FcnCustomDropdown>
    </template>
    <template v-if="type === 'custom'">
      <slot></slot>
    </template>
  </div>
</template>

<script>
import { debounce } from 'lodash'
import FcnCustomDropdown from './FcnCustomDropdown'

/**
 * FcnFormItem is a component which handles user input. It is a combination of all types of inputs.
 * The type might be: text, textarea, select, list, file, checkbox, number, remote
 * text (default):
 * <FcnFormItem title="Name" v-model="form.name" />
 *
 * number:
 * <FcnFormItem title="Age" type="number" v-model="form.age" />
 *
 * select - select 1 item from provided options
 * <FcnFormItem title="Country" type="select" :options="['Poland', 'Germany']" v-model="form.country" />
 *
 * list - select multiple items from provided options. Also, allows filtering and creating new values
 * <FcnFormItem title="Allowed users" type="list" :options="['fcn.abc']" v-model="form.allowedUsers" allow-search allow-create />
 *
 * checkbox
 * <FcnFormItem title="Remember me" type="checkbox" v-model="form.rememberMe" />
 *
 * radio
 * <FcnFormItem title="Type" type="radio" :options=['Value1', 'Value2'] v-model="form.type" />
 *
 * file
 * <FcnFormItem title="Image file" type="file" v-model="form.file" accept=".jpg,.png,.jpeg,.svg"/>
 *
 * textarea - like text, but with multiple rows
 * <FcnFormItem title="Description" type="textarea" v-model="form.desc" />
 *
 * remote - similar to select and list, used for selecting 1 item from a dropdown, but uses remote method to fetch the data from server.
 * Used when there is too many available options.
 * <FcnFormItem title="Fund universe" type="remote" v-model="form.fundUniverse" :remote-method="fundUniverseRemote" debounce="300" />
 */
export default {
  components: { FcnCustomDropdown },
  props: {
    /**
     * Label for input
     */
    title: String,
    /**
     * If needed, additional description can be provided
     */
    description: String,
    /**
     * Type of input
     */
    type: {
      type: String,
      default: 'text',
      validator (value) {
        return ['text', 'textarea', 'remote', 'select', 'custom', 'file', 'checkbox', 'radio', 'number'].includes(value)
      },
    },
    /**
     * Changes type select and list into multiple-choice select
     */
    multiple: Boolean,
    /**
     * Used for v-model binding
     */
    value: [Object, String, Number, Array],
    // select, list
    /**
     * Options to be chosen from select list. Must be an array of values, where value can be a string, number of object with 'label' and 'value keys.
     * E.g.
     * ['Option 1', 'Option 2']
     * [{ label: 'Option 1', value: 1 }, { label: 'Option 2', value: 2 }]
     */
    options: {
      type: Array,
      validator (value) {
        return Array.isArray(value) && value.every(item => typeof item === 'string' || typeof item === 'number' || (item.label && item.value))
      },
      default: () => ([]),
    },
    // file
    /**
     * String specifying what files can be accepted
     * E.g. '.jpg'
     */
    accept: Array,
    // list
    /**
     * Whether or not user can insert new values
     */
    allowCreate: Boolean,
    /**
     * Whether or not user can filter the list
     */
    allowSearch: Boolean,
    // remote
    /**
     * This is a function which must handle making requests to server to fetch options.
     * Is called when user types into searchbar, as param is passed text user has typed
     * E.g.
     * async query (query: string) {
     *   return await ApiService.queryFunds(query)
     * }
     */
    remoteMethod: Function,
    /**
     * Debounce method to throttle how often remoteMethod function is called. Takes ms.
     */
    debounce: Number,
    /**
     * Placeholder for input
     */
    placeholder: String,
  },
  data () {
    return {
      // list
      filterValue: '',
      showDropdown: false,
      createNewValue: '',
      // remote
      remoteItems: [],
      loading: false,
      debouncedRemoteMethod: null,
    }
  },
  created () {
    if (this.type === 'remote') {
      if (!this.remoteMethod) {
        throw new Error('Remote method not provided')
      }
      this.debouncedRemoteMethod = debounce(async query => {
        if (!this.remoteMethod) {
          throw new Error('Remote method not provided')
        }
        this.loading = true
        this.remoteItems = await this.remoteMethod(query)
        this.loading = false
      }, this.debounce || 0)
      this.debouncedRemoteMethod()
    }
    if (this.multiple && !this.value) {
      this.$emit('input', [])
    }
  },
  computed: {
    cValue: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      },
    },
    selectValueLabel () {
      if (!Array.isArray(this.cValue)) {
        const option = this.listOptions.find(opt => opt.value === this.cValue || opt === this.cValue)
        if (option) {
          return option.label || option
        }
      }
      return this.cValue
    },
    listOptions () {
      let arr = this.options
      if (this.filterValue) {
        arr = arr.filter(opt => (opt.label || opt).toString().toLowerCase().includes(this.filterValue.toLowerCase()) ||
            (opt.value || opt).toString().toLowerCase().includes(this.filterValue.toLowerCase()))
      }
      return arr
    },
  },
  methods: {
    onFileInput (ev) {
      this.$emit('input', ev.target.files[0])
    },
    onCreateNewListItem () {
      if (this.createNewValue) {
        if (this.multiple) {
          this.value.push(this.createNewValue)
        }
        this.options.push(this.createNewValue)
        this.createNewValue = ''
      }
    },
    onSelectItemClick (listItem) {
      if ((this.value === listItem.value) || (this.value === listItem)) {
        this.$emit('input', null)
      } else {
        this.$emit('input', listItem.value || listItem)
      }
    },
  },
}
</script>

<style scoped>
.form-item + .form-item {
  margin-top: 6px;
}
input.text-input {
  padding: .4rem 1rem;
  width: 100%;
  border-radius: 4px;
  height: 32px;
  font-size: 14px;
  border: 1px solid #DCDFE6;
}

h4 {
  font-size: 12px;
  color: #4c4c4c;
}
</style>
