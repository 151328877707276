<template>
  <div class="inline-block relative">
    <FcnButton :theme="theme" @click="showDropdown = true" :class="btnClass">{{ label }}</FcnButton>
    <div class="absolute bg-white" v-if="(buttons || []).length && showDropdown" style="top: 100%;" :style="{ width: width }">
      <div class="z-50 bg-white relative border border-gray-200 rounded-sm max-h-60 text-base overflow-auto">
        <FcnButton theme="text" class="w-full" v-for="(btn, index) in buttons" :key="index" style="white-space: nowrap;" :disabled="btn.disabled">
          {{ btn.label }}
        </FcnButton>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * Dropdown component. Example use might be when you want to have a button which after click will make dropdown appear.
 * Must use 2 slots to make it happen. Default slot is for reference button, 'dropdown' slot is for content of a dropdown
 * E.g.
 * <FcnDropdown>
 *   <button>Click me to open dropdown</button>
 *   <template v-slot:dropdown>
 *    Dropdown content here
 *   </template>
 * </FcnDropdown>
 */
export default {
  name: 'FcnDropdown',
  props: {
    /**
     * E.g. '150px'
     */
    width: {
      type: String,
      default: () => 'auto',
    },
    theme: {
      type: String,
      default: 'primary',
    },
    label: {
      type: String,
    },
    btnClass: {
      type: String,
    },
    buttons: {
      type: Array,
      required: true,
    },
  },
  data () {
    return {
      showDropdown: false,
      listener: null,
    }
  },
  watch: {
    showDropdown (isVisible) {
      if (isVisible) {
        this.listener = this.mouseClickListener.bind(this)
        document.addEventListener('mousedown', this.listener)
      } else {
        document.removeEventListener('mousedown', this.listener)
      }
    },
  },
  methods: {
    mouseClickListener (ev) {
      if (!ev.path.includes(this.$refs.dropdown) && !ev.path.includes(this.$refs['dropdown-btn'])) {
        this.showDropdown = false
      }
    },
  },
}
</script>

<style scoped>

</style>
