<template>
  <div>
    <div class="bg-white">
      <div class="relative overflow-hidden">
        <main>
          <div class="pt-10 bg-gray-900 sm:pt-16 lg:pt-16 lg:pb-16 lg:overflow-hidden">
            <div class="mx-auto max-w-7xl lg:px-8">
              <div class="lg:grid lg:grid-cols-2 lg:gap-8">
                <div
                  class="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 sm:text-center lg:px-0 lg:text-left lg:flex lg:items-center"
                >
                  <div class="lg:py-24">
                    <h1 class="mt-4 text-4xl tracking-tight font-extrabold text-white sm:mt-5 sm:text-6xl lg:mt-6 xl:text-6xl">
                      <span class="block text-white">Web solutions</span>
                      <span class="block text-white">created for Nordea</span>
                    </h1>
                    <p class="mt-3 text-base text-gray-300 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
                      Explore data as easy as you can
                    </p>
                  </div>
                </div>
                <div class="mt-12 -mb-16 sm:-mb-48 lg:m-0 lg:relative">
                  <div class="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 lg:max-w-none lg:px-0">
                    <img
                      class="w-full lg:absolute lg:inset-y-0 lg:left-0 lg:h-full lg:w-auto lg:max-w-none"
                      src="@/assets/landing_page_illustration.png"
                      alt=""
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Feature section with screenshot -->
          <div class="relative bg-gray-50 pt-16 sm:pt-24 lg:pt-32">
            <div class="mx-auto max-w-md px-4 text-center sm:px-6 sm:max-w-3xl lg:px-8 lg:max-w-4xl">
              <div>
                <p class="mt-2 text-3xl font-extrabold text-gray-700 tracking-tight sm:text-4xl">
                  Analyze the data
                </p>
                <p class="mt-5 max-w-prose mx-auto text-xl text-gray-500">
                  Explore the details of the data. Download pdf or excel files, compare funds and more.
                </p>
              </div>
              <div class="my-12">
                <img class="rounded-lg shadow-xl ring-1 ring-black ring-opacity-5"
                     src="@/assets/nordeagif.gif"
                     alt=""
                >
              </div>
            </div>
          </div>

          <!-- Feature section with grid -->
          <div class="relative bg-white py-16 sm:py-24 lg:py-32">
            <div class="mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">
              <p class="mt-2 text-3xl font-extrabold text-gray-700 tracking-tight sm:text-4xl">
                More dashboards
              </p>
              <p class="mt-5 max-w-prose mx-auto text-xl text-gray-500">
                Phasellus lorem quam molestie id quisque diam aenean nulla in. Accumsan in quis quis
                nunc, ullamcorper malesuada. Eleifend condimentum id viverra nulla.
              </p>
              <div class="mt-12">
                <div class="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
                  <LandingPageCard title="Dashboard #1"
                                   desc="Ac tincidunt sapien vehicula erat auctor pellentesque rhoncus.
                          Et magna sit morbi lobortis."
                                   :image="require(`@/assets/landing_page_img_1.png`)"
                  />
                  <LandingPageCard title="Example template"
                                   desc="Ac tincidunt sapien vehicula erat auctor pellentesque rhoncus.
                              Et magna sit morbi lobortis."
                                   :image="require(`@/assets/landing_page_img_2.png`)"
                  />
                  <LandingPageCard title="Fund List #1"
                                   desc="Ac tincidunt sapien vehicula erat auctor pellentesque rhoncus.
                          Et magna sit morbi lobortis."
                                   :image="require(`@/assets/landing_page_img_3.png`)"
                  />
                  <LandingPageCard title="Fund List #2"
                                   desc="Ac tincidunt sapien vehicula erat auctor pellentesque rhoncus.
                          Et magna sit morbi lobortis."
                                   :image="require(`@/assets/landing_page_img_4.png`)"
                  />

                  <LandingPageCard title="Dashboard #5"
                                   desc="Ac tincidunt sapien vehicula erat auctor pellentesque rhoncus.
                          Et magna sit morbi lobortis."
                                   :image="require(`@/assets/landing_page_img_5.png`)"
                  />

                  <LandingPageCard title="Dashboard #6"
                                   desc="Ac tincidunt sapien vehicula erat auctor pellentesque rhoncus.
                          Et magna sit morbi lobortis."
                                   :image="require(`@/assets/landing_page_img_6.png`)"
                  />
                </div>
              </div>
            </div>
          </div>
        </main>
        <LandingPageFooter />
      </div>
    </div>
  </div>
</template>

<script>
import LandingPageCard from '@/views/landingPage/internal/LandingPageCard'
import LandingPageFooter from '@/views/landingPage/internal/LandingPageFooter'
export default {
  name: 'LandingPage',
  components: { LandingPageFooter, LandingPageCard }
}
</script>

<style scoped>

</style>
