/**
 * Notifications service.
 */
export class FcnNotification {
  /**
   * Used in FcnApp component.
   * @param component
   */
  static mount (component) {
    this.component = component
  }

  static msg (opt) {
    if (this.component) {
      this.component.add(opt)
    }
  }

  static success (title, desc) {
    this.msg({
      type: 'success',
      title,
      desc,
    })
  }

  static error (title, desc) {
    this.msg({
      type: 'warning',
      title,
      desc,
    })
  }
}
