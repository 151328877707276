<template>
	<div>
		<div class="header">
			<div class="new-logo">
				<img
					@click="FCIconClick()"
					:src="logo"
					alt="FundConnect logo"
				/>
			</div>
			<SearchBar />
			<UserPopover />
			<div>
				<el-tabs v-model="activeTab" @tab-click="handleTabClick">
					<el-tab-pane label="Home" name="home"></el-tab-pane>
					<el-tab-pane
						v-for="(tab, i) in navigation.filter(
							(n) => !n.disabled
						)"
						:key="i"
						:label="tab.name"
						:name="tab.id"
						:disabled="tab.disabled"
					></el-tab-pane>
					<el-tab-pane
						v-if="starred.length > 0"
						label="Starred"
						name="starred"
					></el-tab-pane>
				</el-tabs>
			</div>
		</div>
		<NavDrawer />
	</div>
</template>

<script>
import { isEmpty } from 'lodash'
import { mapState } from 'vuex'
import NavDrawer from '@/components/nav/NavDrawer.vue'
import SearchBar from '@/components/nav/SearchBar.vue'
import UserPopover from '@/components/nav/UserPopover.vue'

export default {
	name: 'NavBar',
	components: { NavDrawer, UserPopover, SearchBar },
	data() {
		return {
			logo: require('@/assets/nordea_logo.png'),
			user: null
		}
	},
	watch: {
		drawer() {
			if (!this.drawer && isEmpty(this.$route.params))
				this.activeTab = 'home'
		},
		activeLink() {
			this.activeTab = this.activeLink.categoryId
		}
	},
	computed: {
		...mapState(['navigation', 'drawer', 'activeLink', 'starred']),
		activeTab: {
			get: function () {
				return this.$store.getters.activeTab
			},
			set: function (newValue) {
				this.$store.commit('setActiveTab', newValue)
			}
		}
	},
	mounted() {
		this.getCurrentRouteCategory()
	},
	methods: {
		isAdmin() {
			if (this.$authSession.User?.Roles.includes('admin')) return true
			else return false
		},
		getCurrentRouteCategory() {
			if (this.$route.name !== 'MainPage' && !isEmpty(this.$route.params))
				this.activeTab = this.$route.params.category.toLowerCase()
		},
		FCIconClick() {
			this.$router.push({ name: 'MainPage' })
			this.activeTab = 'home'
			this.$store.commit('setDrawer', false)
		},
		handleTabClick(tab, event) {
			if (tab.name === 'home') {
				this.$router.push({ name: 'MainPage' })
				this.$store.commit('setDrawer', false)
			} else this.$store.commit('setDrawer', true)
		}
	}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
@import '~@/assets/variables.scss';

.header {
	position: fixed;
	width: 100vw;
	z-index: 100;
	background: $white;
	opacity: $opacity-header;
	box-shadow: $shadow-medium;
	height: $navbar-height;

	.new-logo {
		position: absolute;
		margin: $margin-small $margin-medium;

		span {
			margin-left: $margin-mini;
		}

		img {
			cursor: pointer;
			height: 28px;
		}
	}
	.el-tabs {
		display: inline-block;
		position: relative;
		top: $margin-mini;
		left: 110px;
		width: 75vw;

		.el-tabs__nav-wrap::after {
			display: none;
		}
	}
}

@media only screen and (max-width: 1600px) {
	.header {
		.el-tabs {
			width: 70vw;
		}
	}
}

@media only screen and (max-width: 1200px) {
	.header {
		.el-tabs {
			width: 65vw;
		}
	}
}

@media only screen and (max-width: 900px) {
	.header {
		.el-tabs {
			width: 55vw;
		}
	}
}
</style>
