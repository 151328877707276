import store from '../store'
import { flattenDeep } from 'lodash'

class StarredService {
    addStar(nav) {
        if (store.getters.starred.findIndex(sId => sId === nav.id) > -1) {
            this.removeStar(nav.id)
            return
        }
        store.dispatch("storeStarred", [...store.getters.starred, nav.id]);
    }

    removeStar(navId) {
        let index = store.getters.starred.findIndex(sId => sId === navId)
        if (index > -1) {
            let starred = store.getters.starred
            starred.splice(index, 1)
            store.dispatch("storeStarred", starred);
            if (store.getters.activeTab === 'starred' && store.getters.starred.length < 1) {
                store.commit('setDrawer', false)
            }
        }
    }

    starredCategories(starred) {
        let categories = store.getters.navigation.map(nav => ({
            ...nav,
            _links: []
        }))

        starred.forEach(linkId => {
            let i = categories.findIndex(category => category.children.some(child => child.id === linkId))
            if (i > -1) {
                categories[i]._links.push(linkId)
            }
        })
        return categories.filter(s => s._links.length > 0)
    }

    getLinksFromIds (ids) {
        return flattenDeep(store.getters.navigation.map(nav => nav.children.filter(child => ids.includes(child.id))))
    }
}

export default new StarredService();
