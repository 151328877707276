/**
 * Abstract class which is inherited by all of micro frontend implementations
 */
export default class MicroFrontend {
  constructor ({ id, name } = {}) {
    this.id = id
    this.name = name
    this.rootEl = null
    this.moduleEl = null

    this.mount = this.mount.bind(this)
    this.unmount = this.unmount.bind(this)
    this.isLoading = this.isLoading.bind(this)
  }

  mount () {
    throw Error('Abstract method')
  }

  unmount () {
    throw Error('Abstract method')
  }

  isLoading () {
    return false
  }
}
