<template>
    <FcnApp v-if="userAuthenticated()" id="nexus-app" class="app">
        <NavBar />
        <el-main v-if="navigation" class="app__main">
            <router-view />
        </el-main>
    </FcnApp>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import NavBar from '@/components/nav/NavBar.vue'
import FcnApp from '../libs/fcn-vue/components/FcnApp'
import LandingPage from '@/views/landingPage/LandingPage'

export default {
    name: 'App',
    components: { LandingPage, FcnApp, NavBar },
    computed: {
        ...mapState(['navigation'])
    },
    mounted() {
        this.loadStarredFromBrowser()
    },
    watch: {
        $authSession: {
            immediate: true,
            async handler(session) {
                if (session.Token) {
                    this.updateNavigation(session.User.Roles)
                }
            }
        }
    },
    methods: {
        userAuthenticated(){
            if(this.$authSession.Token && this.$authSession.User.Roles.includes("nordea-portal")){
                return true
            }
            else return false
        },
        ...mapActions(['updateNavigation', 'loadStarredFromBrowser'])
    }
}
</script>
<style lang="scss" scoped>
@import '~@/assets/variables.scss';

.app {
    @include font-settings;

    &__main {
        padding: $navbar-height 0 0 0;
    }
}
</style>
